import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Col, Row, Form, Button} from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getHealthBenefitByCodeAction, saveExamParamsAction } from 'happ-redux/happ-actions/exams/examsActions';
import LastResultExam from 'happ-components/LastResultExam/LastResultExam';
import ErrorMessage from 'happ-components/Alerts/ErrorMessage';
import { getInputToValidate } from 'happ-custom-hooks/valid-ranges/valid-ranges';

const initState = {
    glycemia: '',
    fast: true,
}

const HemoglucotestForm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [errorsInput, setErrorsInput] = useState(null);
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    const {
        state,
        errors,
        handlerChange,
        handlerChangeNumber,
        handlerSubmit
    } = useValidatorForm(initState, sendResults, 'hemoglucotest');
    const {glycemia, fast} = state;
    useEffect(() => {
        dispatch(getHealthBenefitByCodeAction('hemoglucotest', 'dc-1', navigate))
    }, [])
    const { patient, currentMedicalStage, operativeId } = useSelector(state => state.profile);
    const { healthBenefit, errorHealthBenefit, saveParams } = useSelector(state => state.exams);
    const {loginProfile, summaryResults} = useSelector(state => state.auth);
    function sendResults() {
        const errorsInputs = getInputToValidate('hemoglucotest', {glycemia});
        if (Object.keys(errorsInputs).length) {
            setErrorsInput(errorsInputs);
            return;
        }
        setErrorsInput(null);
        const body = {
            patient: patient._id,
            healthBenefits: healthBenefit._id,
            status: true,
            admin: loginProfile._id,
            operative: operativeId,
            medicalStage: currentMedicalStage._id,
            origin: 'Happ',
            algorithmName: 'diabetes',
            params: {
                glicemia: Number(glycemia),
                fast
            }

        }
        dispatch(saveExamParamsAction(navigate, body, 'dc-1'));
        state.glycemia = '';
        state.fast = false;
    }
    return ( 
        <>
        {saveParams !== null && saveParams ? (
            <SweetAlert2
                {...examMessage}
            />
        ) : null} 
        {saveParams !== null && !saveParams ? (
            <Row className="align-items-center mt-5">
                <p className='success-save'>No se pudo registrar el resultado</p>
            </Row>
        ) : null} 
        <Row className="justify-content-md-center">
            <Col md={7} sm={7} lg={7} className="content-form">
                <Form
                    onSubmit={handlerSubmit}
                >
                    <Row className='justify-content-md-center'>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Check 
                                type="switch"
                                className='switch-imc'
                                id="custom-switch"
                                name="fast"
                                onChange={handlerChange}
                                checked={fast}
                                label="¿En ayunas?"
                            /> 
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Label>Glicemia</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="glycemia" 
                                onChange={handlerChangeNumber}
                                placeholder="Glicemia"
                                value={glycemia ? glycemia : ''} 
                            />
                            {errors && errors.glycemia ? (
                                <p>{errors.glycemia}</p>
                            ) : null}                            
                        </Col>
                    </Row>
                    <Col md={12} sm={12} lg={12} className="mt-3 text-center">
                        <Button
                            type='submit'
                            className='btn btn-primary'
                        >Guardar</Button>
                    </Col>
                </Form>
            </Col>
        </Row>
        {errorsInput && Object.keys(errorsInput).length ? (
            <Row className="justify-content-md-center">
                {Object.keys(errorsInput).map((error) => (
                    <ErrorMessage 
                        key={error} 
                        message={errorsInput[error]}
                    />
                ))}
            </Row>
        ) : null}
        <Row className="justify-content-md-center">
            {summaryResults && summaryResults.length ? (
                <LastResultExam 
                    origin={'Hemoglucotest'}
                    result={summaryResults}
                />
            ) : null
            }
        </Row>
        </>
     );
}
 
export default HemoglucotestForm;