import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Container, Row, Col, Spinner} from 'react-bootstrap';

//Sub views
import Survey from './tester-views/Survey/Survey';
import Antropometria from './tester-views/Antropometria/Antropometria';
import BloodPressure from './shared-views/BloodPressure/BloodPressure';
import BloodPressureTwo from './shared-views/BloodPressureTwo/BloodPressureTwo';
import Hemoglucotest from './tester-views/Hemoglucotest/Hemoglucotest';
import Profile from './shared-views/Profile/Profile';
import AssignBox from './shared-views/AssignBox/AssignBox';
import LaboratoryExams from './lab-views/LaboratoryExams/LaboratoryExams';
import DniTopsearch from 'happ-components/DniTopSearch/DniTopSearch';
import PatientOptionsMenu from 'happ-components/PatientOptionsMenu/PatientOptionsMenu';
import FormEdema from './tester-views/FormEdema/FormEdema';
import FormEcg from './tester-views/FormEcg/FormEcg';
import FormAuscultacion from './tester-views/FormAuscultacion/FormAuscultacion';
import ConsentSignature from './shared-views/ConsentSignature/ConsentSignature';
import ProstateAntigen from './shared-views/ProstateAntigen/ProstateAntigen';

import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';

import { getPatientByDniAction } from 'happ-redux/happ-actions/patient-profile/patientProfileActions';
import { getConsentSignatureStatusAction, cleanConsetSignatureAction } from 'happ-redux/happ-actions/consent-signature/consentSignatureAction';
import { summaryPatientAction } from 'happ-redux/happ-actions/auth/authActions';

const topDni = {
    dni: ''
}
const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const {isValidProfile} = useSelector(state => state.profile);
    const [options, showOptions] = useState({
        consentSignature: false,
        profile: false,
        formEdema: false,
        formAuscultacion: false,
        formEcg: false,
        survey: false,
        bloodPressure: false,
        bloodPressureTwo: false,
        anthropromesia: false,
        hemoglucotest: false,
        box: false,
        laboratoryExams: false,
        prostateAntigen: false,
    });
    const { patient, currentMedicalStage, operativeId, isHypertensive } = useSelector(state => state.profile);
    const { saveParams } = useSelector(state => state.exams);
    const {
        state,
        errors,
        handlerChangeDni,
        handlerSubmit
    } = useValidatorForm(topDni, sendResults, 'top-dni');
    const {dni} = state;
    useEffect(() => {
        if (patient !== null || options.consentSignature) {
            dispatch(cleanConsetSignatureAction())
            dispatch(getConsentSignatureStatusAction(patient?._id));
        }
    }, [patient, options]);


    useEffect(() => {
        if (
            (patient !== null &&
            (
                options.bloodPressure || 
                options.hemoglucotest ||
                options.anthropromesia
            )) || (saveParams !== null && saveParams === true) 
        ) {
            dispatch(summaryPatientAction(patient?._id, operativeId, currentMedicalStage, isHypertensive, navigate));
        }
    }, [patient, options, saveParams]);


    function sendResults() {
        dispatch(getPatientByDniAction(dni, navigate));
    }
    const showContent = option => e => {
        e.preventDefault();
        setLoading(!loading);
        Object.keys(options).forEach(key => {
            options[key] = false;
        });
        options[option] = true;
        setTimeout(() => {
            setLoading(loading);
            showOptions({
                ...options,
                options
            });
        }, 500);
    }
    const cleanSession = e => {
        window.location.href = '/';
    }
    return ( 
        <Container className="mt-5 home-container">
            <Row className=''>
                <Col xs={12} md={12} lg={12}>
                    <DniTopsearch 
                        state={state}
                        errors={errors}
                        handlerChangeDni={handlerChangeDni}
                        handlerSubmit={handlerSubmit}
                    />
                </Col>
            </Row>
            {isValidProfile ? (
                <>
                    <Row className="mt-5 box-menu-options">
                        <Col xs={12} md={12} lg={12}>
                            <PatientOptionsMenu
                                showContent={showContent}
                                cleanSession={cleanSession} 
                            />
                        </Col>    
                    </Row>
                    <Row className="mt-5 mb-5">
                        {loading ? (
                            <Col xs={12} md={12} lg={12} className="content-spinner">
                                <Spinner 
                                    animation="border"
                                />
                            </Col>
                        ) : null}
                        <Col xs={12} md={12} lg={12} className="content-data">
                            {options.consentSignature && !loading ? (
                                <ConsentSignature />
                            ) : null}
                            {options.profile && !loading ? (
                                <Profile />
                            ) : null}
                            {options.survey && !loading ? (
                                <Survey />
                            ) : null}
                            {options.bloodPressure && !loading ? (
                                <BloodPressure />
                            ) : null}
                            {options.bloodPressureTwo && !loading ? (
                                <BloodPressureTwo />
                            ) : null}
                            {options.anthropromesia && !loading ? (
                                <Antropometria />
                            ) : null}
                            {options.hemoglucotest && !loading ? (
                                <Hemoglucotest />
                            ) : null}
                            {options.box && !loading ? (
                                <AssignBox />
                            ) : null}
                            {options.laboratoryExams && !loading ? (
                                <LaboratoryExams />
                            ) : null}
                            {options.formEdema && !loading ? (
                                <FormEdema />
                            ) : null}
                            {options.formEcg && !loading ? (
                                <FormEcg />
                            ) : null}
                            {options.formAuscultacion && !loading ? (
                                <FormAuscultacion />
                            ) : null}
                            {options.prostateAntigen && !loading ? (
                                <ProstateAntigen />
                            ) : null}
                        </Col>
                    </Row>            
                </>
            ) : null}
        </Container>    
     );
}
 
export default Home;