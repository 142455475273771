import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Table, Col, Spinner} from 'react-bootstrap';
import { getPatientListAction } from 'happ-redux/happ-actions/patient-list/patientListAction';
import moment from 'moment';

const usePatientList = (initialState) => {
    const dispatch = useDispatch();
    const [patientList, setPatientList] = useState(null);
    const [state, setState] = useState(initialState);
    const {dni} = state;
    const { operatives } = useSelector(state => state.auth);
    const { patients, loading } = useSelector(state => state.patients);
    useEffect(() => {
        if (operatives !== null && operatives.length) {
            const body = {
                operatives
            }
            dispatch(getPatientListAction(body));
        }
    }, [operatives]);
    useEffect(() => {
        if (patients && patients.length) {
            setPatientList(patients)
        }
    }, [patients])
    
    const handlerChangeDni = e => {
        const dni = e.target.value;
        setState({
            ...state,
            dni
        });
    }

    const searchByDni = e => {
        e.preventDefault();
        if (dni !== '') {
            const updatePatientList = patients?.filter(patient => patient.patient.dni === dni);
            setPatientList(updatePatientList);
        }
    }

    const refreshPatientList = e => {
        e.preventDefault();
        const body = {
            operatives
        }
        dispatch(getPatientListAction(body));
        if (patients && patients.length) {
            setPatientList(patients);
        }
        state.dni = '';
    } 
    
    const PatientListTable = () => {
        return (
            <>
            {loading ? (
                <>
                <Col xs={12} md={12} lg={12} className="content-spinner">
                    <Spinner 
                        animation="border"
                    />
                </Col></>
            ) : (
                <>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Nombre paciente</th>
                            <th>Rut</th>
                            <th>Empresa</th>
                            <th>Sucursal</th>
                            <th>Cargo</th>
                            <th>Fecha (Día-Mes-Año)</th>
                            <th>Hora</th>
                            <th>Tipo de operativo</th>
                            <th>Asistencia</th>
                            <th>Box asignado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {patientList && patientList.length > 0 ? patientList.map((patient, pos) => (
                                <tr key={pos}>
                                    <td>{pos + 1}</td>
                                    <td>{patient.patient.fullName}</td>
                                    <td>{patient.patient.dni}</td>
                                    <td>{patient.company.name}</td>
                                    <td>{patient.branchOffice.name}</td>
                                    <td>{patient.patient.position}</td>
                                    <td>{moment.utc(patient.day).format('DD-MM-YYYY')}</td>
                                    <td>{patient.hour}</td>
                                    <td>{patient.medicalStage.name}</td>
                                    <td>{patient.attendance}</td>
                                    <td>{patient.box === null ? 'Por asignar' : patient.box.box.name}</td>
                                </tr>
                            )) : (
                                <tr >
                                    <td>No hay resultados disponibles</td>
                                </tr>
                            )}    
                        </tbody>
                    </Table>
                </>

            )}
            </>
        );
    }

    return {
        state,
        PatientListTable,
        handlerChangeDni,
        searchByDni,
        refreshPatientList
    }
}
 
export default usePatientList;