import axiosClient from 'happ-config/axios';

const getPatientData = async (token, patient) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const response = await axiosClient.get(`/consent-signatures/patients/${patient}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getPreviewPdf = async (token, patient) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const response = await axiosClient.get(`/consent-signatures/patients/${patient}/preview-pdf`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getSignedPdf = async (token, patient) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const response = await axiosClient.get(`/consent-signatures/patients/${patient}/signed-pdf`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const acceptTerms = async (token, patient, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const response = await axiosClient.post(`/consent-signatures/patients/${patient}/accept-terms`, body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const confirmTerms = async (token, patient, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const response = await axiosClient.post(`/consent-signatures/patients/${patient}/confirm-terms`, body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const confirmPaperTerms = async (token, patient) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
        const response = await axiosClient.post(`/consent-signatures/patients/${patient}/physical-consent`, {}, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}


export {
    getPatientData,
    acceptTerms,
    getPreviewPdf,
    getSignedPdf,
    confirmTerms,
    confirmPaperTerms,
}