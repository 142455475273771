import React, {useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import { savePhysicalExamAction } from 'happ-redux/happ-actions/exams/examsActions';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';

const ausPState = {
    observationAusPulmon: '',
    normalAusPulmon: -1,
    obsCrePul: -1,
}

const FormAuscultacion = () => {
    const dispatch = useDispatch();
    const { statusSavePhysicalExam } = useSelector(state => state.exams);
    const { patient } = useSelector(state => state.profile);
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    const { 
        state,
        errors,
        handlerChange,
        handlerSubmit } = useValidatorForm(ausPState, saveObservationAusP, 'exam-aus-pul');
        const {
            observationAusPulmon,
            normalAusPulmon,
            obsCrePul,
        } = state;
        function saveObservationAusP() {
            const body = new FormData();
            const params = {
                patientId: patient?._id,
                examName: 'auscultacion-pulmonar',
                results: {
                    normalAusPulmon,
                    obsCrePul 
                },
                observations: observationAusPulmon,
            }
            body.append('body', JSON.stringify(params));
            dispatch(savePhysicalExamAction(body));
            state.observationAusPulmon = '';
            state.normalAusPulmon = -1;
            state.obsCrePul = -1;
        }
    return ( 
        <>
            {statusSavePhysicalExam !== null && statusSavePhysicalExam ? (
                <SweetAlert2
                    {...examMessage}
                />
            ) : null}
            <Row className="justify-content-md-center">
                <Col md={7} sm={7} lg={7} className="content-form">
                    <div className='text-center'>
                        <Form.Label className='mb-5 title-physical-exams'>Examen Auscultación Pulmonar</Form.Label>
                    </div>
                    <Form
                    onSubmit={handlerSubmit}
                >
                    <Col xs={12} md={12} lg={12}>
                        <div className='title-exam'>
                            <div className='content-info-2 margin-0'>
                                <div>
                                    <h6>Auscultación Normal </h6>
                                    <Form.Check
                                        inline
                                        label="Si"
                                        value={1}
                                        checked={String(normalAusPulmon) === "1"}
                                        onChange={handlerChange}
                                        name="normalAusPulmon"
                                        type="radio"
                                        id={`inline--1`}
                                    />
                                    <Form.Check
                                        inline
                                        label="No"
                                        value={0}
                                        checked={String(normalAusPulmon) === "0"}
                                        onChange={handlerChange}
                                        name="normalAusPulmon"
                                        type="radio"
                                        id={`inline--1`}
                                    />
                                </div>
                                <div>
                                    <h6>Observación Crépitos </h6>
                                    <Form.Check
                                        inline
                                        label="Si"
                                        name="obsCrePul"
                                        checked={String(obsCrePul) === "1"}
                                        onChange={handlerChange}
                                        value={1}
                                        type="radio"
                                        id={`inline--1`}
                                    />
                                    <Form.Check
                                        inline
                                        value={0}
                                        checked={String(obsCrePul) === "0"}
                                        onChange={handlerChange}
                                        label="No"
                                        name="obsCrePul"
                                        type="radio"
                                        id={`inline--1`}
                                    />
                                </div>
                            </div>
                        </div>
                        <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Observaciones</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                rows={3} 
                                value={observationAusPulmon ? observationAusPulmon : ''}
                                onChange={handlerChange}
                                name="observationAusPulmon"
                            />
                        </Form.Group>
                            {errors.normalAusPulmon || errors.obsCrePul || errors.observationAusPulmon ? (
                                <div className='error-group'>
                                    <small>Acciones requeridas: </small>
                                    {errors.normalAusPulmon ? (
                                        <p>{errors.normalAusPulmon}</p>
                                    ) : null}
                                    {errors.obsCrePul ? (
                                        <p>{errors.obsCrePul}</p>
                                    ) : null}
                                    {errors.observationAusPulmon ? (
                                        <p>{errors.observationAusPulmon}</p>
                                    ) : null}
                                </div>
                            ) : null}
                            <Button 
                                variant="primary"
                                className='btn-save-observation' 
                                type="submit"
                            >
                                {'Guardar'}
                            </Button>
                    </Col>
                </Form>
                </Col>
            </Row>        
        </>
    );
}
 
export default FormAuscultacion;