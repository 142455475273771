import React from 'react';
import HemoglucotestForm from 'happ-components/HemoglucotestForm/HemoglucotestForm';

import InfoPatientNav from 'happ-components/Navbar/InfoPatientNav';

const Hemoglucotest = () => {
    return ( 
        <>
        <InfoPatientNav />
        <HemoglucotestForm />
        </>
     );
}
 
export default Hemoglucotest;