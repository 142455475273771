import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import {Col, Row, Form, Button} from 'react-bootstrap';

import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';

import { saveSurveyAction } from 'happ-redux/happ-actions/survey/surveyActions';

const initState = {
    survey: []
}
const SurveyForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isSaveSurvey } = useSelector(state => state.survey);
    const { patient } = useSelector(state => state.profile);
    const [surveyMessage, setSurveyMessage] = useState({
        show: true,
        text: 'Encuesta registrada con exito',
        type: 'success',
        icon: 'success',
    });
    const {
        state,
        handlerChangeSurvey,
        handlerSubmit,
        resetSurvey
    } = useValidatorForm(initState, sendResults, 'survey');
    const {survey} = state;
    function sendResults() {
        dispatch(saveSurveyAction(navigate, state, patient._id, 'rcv-survey', 'Encuesta RCV'));
        resetSurvey();
    }
    return (
        <>
        <Row className="justify-content-md-center">
            <Col md={9} sm={9} lg={9} className="content-survey">
            <Col md={12} sm={12} lg={12}>
                <h4>Encuesta Rcv</h4>
            </Col>
                <Form
                    onSubmit={handlerSubmit}
                >
                    <Row className='mb-3 header-info-doc'>
                        {survey ? survey.map(question => (
                            <Col md={12} sm={12} lg={12} className="col-question" key={question.code}>
                                <Form.Check
                                    inline
                                    className='check-survey'
                                    label={question.item}
                                    checked={question.value}
                                    onChange={handlerChangeSurvey}
                                    name={question.code}
                                    type="checkbox"
                                />
                            </Col>
                        )) : null}
                        <Col md={12} sm={12} lg={12} className="mt-3">
                            <Button
                                type='submit'
                                className='btn btn-primary btn-survey'
                            >Guardar</Button>
                        </Col>   
                    </Row>        
                </Form>
            </Col>
        </Row>
        {isSaveSurvey !== null && isSaveSurvey ? (
            <SweetAlert2
                {...surveyMessage}
            />
        ) : null} 
        {isSaveSurvey !== null && !isSaveSurvey ?(
            <Row className="align-items-center mt-5">
                <p className='error-save'>Hubo un error al registrar la encuesta</p>
            </Row>
        ): null}
        </>
    );
}
 
export default SurveyForm;