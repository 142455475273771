import { 
    INIT_SAVE_SURVEY, 
    SAVE_SURVEY, 
    ERROR_SAVE_SURVEY,
    ERROR_TOKEN,
    CLEAN_STATUS_SURVEY, 
} from "happ-redux/happ-types";
import { saveSurvey } from "happ-redux/happ-services/survey/survey-service";

export function saveSurveyAction(navigate, survey, patient, type, name) {
    return async dispatch => {
        dispatch(initSaveSurveyPatient());
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const response = await saveSurvey(token, survey, patient, type, name);
                if (response.status === 201) {
                    dispatch(saveSurveyPatient(true));
                    setTimeout(() => {
                        dispatch(cleanStatus());
                    }, 2000);
                } else {
                    dispatch(errorSaveSurveyPatient(false));
                    setTimeout(() => {
                        dispatch(cleanStatus());
                    }, 2000);
                }
            } else {
                dispatch(errorToken(false));
                navigate('/login');
            }
        } catch (error) {
            dispatch(errorSaveSurveyPatient(false));
            setTimeout(() => {
                dispatch(cleanStatus());
            }, 2000);
        }
    }
}

const initSaveSurveyPatient = () => ({
    type: INIT_SAVE_SURVEY
});

const saveSurveyPatient = response => ({
    type: SAVE_SURVEY,
    payload: response
});

const errorSaveSurveyPatient = error => ({
    type: ERROR_SAVE_SURVEY,
    payload: error
});

const errorToken = () => ({
    type: ERROR_TOKEN
});

const cleanStatus = () => ({
    type: CLEAN_STATUS_SURVEY
});