import React from 'react';
import { Row, Button, Form, Col } from 'react-bootstrap';
import { ExclamationTriangleFill, CursorFill } from 'react-bootstrap-icons';
import ErrorMessage from 'happ-components/Alerts/ErrorMessage';

const SendConsentSignatureCode = ({
    phoneCode,
    phone,
    email,
    errors,
    handlerChangeNumber,
    handlerChange,
    sendConsentSignatureCodeSMS,
    getPreviewPdf,
}) => {
    return ( 
        <Row>
            <div className='d-flex justify-content-center'>
                <div className='mt-5'>
                    <p className='pending-status-consent-signature'>
                        Estado: 
                        <strong>Consentimiento pendiente</strong>
                        <ExclamationTriangleFill 
                            className='pending-icon-check-signature'
                        />
                    </p>
                </div>
            </div>
            <div className='justify-content-center content-info-signature'>
                <div className='mt-5 bullets-signature'>
                    <p><strong>IMPORTANTE</strong>: Por favor, confirme la siguiente información: </p>
                    <p> <CursorFill className='cursor-pointer-icon' /> Se enviará un correo electrónico con el consentimiento que deberá firmar el paciente.​ </p>
                    <p> <CursorFill className='cursor-pointer-icon' /> Se enviará un SMS al número registrado para aceptar el consentimiento.​ </p>
                </div>
            </div>
            <div className='justify-content-center mt-5 mb-5 content-inputs-patient-signature'>
                    <Form.Label className='label-signature'>
                        Correo Electronico
                    </Form.Label>
                    <Col>
                    <Form.Control
                        name='email' 
                        type="email" 
                        onChange={handlerChange}
                        className={errors && errors.email ? 'input-form-signature border-require' : 'input-form-signature'}
                        value={email !== '' ? email : ''}
                        placeholder="Email" 
                    />
                    </Col>
                    <Form.Label className='label-signature' column>
                        Teléfono Celular
                    </Form.Label>
                    <div className='d-flex inputs-flex'>
                        <Col className='code-phone'>
                            <Form.Control 
                                type="text" 
                                placeholder="" 
                                onChange={handlerChangeNumber}
                                value={phoneCode !== '' ? phoneCode : ''}
                                className={errors && errors.phoneCode ? 'input-code-phone border-require' : 'input-code-phone'}
                                name="phoneCode"
                            />
                        </Col>
                        <Col>
                            <Form.Control 
                                type="text" 
                                onChange={handlerChangeNumber}
                                value={phone !== '' ? phone : ''}
                                className={errors && errors.phone ? 'input-form-signature border-require' : 'input-form-signature'}
                                name="phone"
                                placeholder="" 
                            />
                        </Col>
                    </div>
            </div>
            <div className='justify-content-center d-flex-btn mt-4 mb-5 content-info-signature'>
                {errors ? (
                    <>
                        {Object.keys(errors).map((error, pos) => (
                            <>
                                <ErrorMessage
                                    key={pos}
                                    message={errors[error]}
                                />
                            </>
                        ))}
                    </>
                ): null}
            </div>
            <div className='d-flex justify-content-center d-flex-btn mt-4 mb-5'>
                <Button
                    onClick={getPreviewPdf}    
                >Ver Consentimiento a firmar</Button>
                <Button
                    onClick={sendConsentSignatureCodeSMS}
                >Enviar código</Button>
            </div>
        </Row>
     );
}
 
export default SendConsentSignatureCode;