import {
    INIT_GET_HEALTH_BENEFIT_ID,
    GET_HEALTH_BENEFIT_ID,
    ERROR_GET_HEALTH_BENEFIT_ID,
    INIT_SAVE_EXAM_PARAMS,
    SAVE_EXAM_PARAMS,
    ERROR_SAVE_EXAM_PARAMS,
    CLEAN_STATUS_EXAMS,
    INIT_GET_HEALTH_BENEFITS_BY_PATIENT,
    GET_HEALTH_BENEFITS_BY_PATIENT,
    ERROR_GET_HEALTH_BENEFITS_BY_PATIENT,
    INIT_HEALTH_BENEFITS_UPDATE_LIST,
    HEALTH_BENEFITS_UPDATE_LIST,
    ERROR_HEALTH_BENEFITS_UPDATE_LIST,
    INIT_GENERATE_MEDICAL_AGENDA,
    GENERATE_MEDICAL_AGENDA,
    ERROR_GENERATE_MEDICAL_AGENDA,
    INIT_VALIDATE_MEDICAL_AGENDA,
    VALIDATE_MEDICAL_AGENDA,
    ERROR_VALIDATE_MEDICAL_AGENDA,
    INIT_SAVE_PHYSICAL_EXAM,
    SAVE_PHYSICAL_EXAM,
    ERROR_SAVE_PHYSICAL_EXAM,
    INIT_GET_LAST_PHYSICAL_EXAM,
    GET_LAST_PHYSICAL_EXAM,
    ERROR_GET_LAST_PHYSICAL_EXAM,
    INIT_GET_EXAMS_LIST,
    GET_EXAMS_LIST,
    ERROR_GET_EXAMS_LIST,
    INIT_GET_EXAM_RESULTS_HISTORY,
    GET_EXAM_RESULTS_HISTORY,
    ERROR_GET_EXAM_RESULTS_HISTORY,
    INIT_SAVE_PROSTATE_ANTIGEN,
    SAVE_PROSTATE_ANTIGEN,
    ERROR_SAVE_PROSTATE_ANTIGEN,
    INIT_GET_PROSTATE_ANTIGEN,
    GET_PROSTATE_ANTIGEN,
    ERROR_GET_PROSTATE_ANTIGEN
} from 'happ-redux/happ-types';

const initialState = {
    healthBenefit: null,
    errorHealthBenefit: false,
    saveParams: null,
    errorHealthBenefitMsg: null,
    loading: false,
    loadingSavesPhysical: false,
    examList: null,
    examListError: false,
    updateExamList: false,
    isMedicalAgenda: false,
    medicalAgendaMsg: null,
    checkMedicalAgenda: null,
    statusSavePhysicalExam: null,
    lastPhysicalExam: null,
    isLastPhysicalExam: false,
    loadingExams: false,
    pdfOrder: null,
    examResults: null,
    prostateAntigen: null,
    errorProstateAntigen: false,
    isProstateAntigenStatusSave: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){

        case INIT_GET_HEALTH_BENEFIT_ID:
            case INIT_GET_HEALTH_BENEFITS_BY_PATIENT:   
            case INIT_HEALTH_BENEFITS_UPDATE_LIST:
            case INIT_VALIDATE_MEDICAL_AGENDA:
            case INIT_GET_LAST_PHYSICAL_EXAM:
            case INIT_GET_EXAM_RESULTS_HISTORY:    
            case INIT_GET_PROSTATE_ANTIGEN:
                return {
                    ...state,
                    loading: true
                }
            case INIT_SAVE_PROSTATE_ANTIGEN:
                return {
                    ...state,
                    loading: true,
                    isProstateAntigenStatusSave: null,
                }    
            case INIT_SAVE_EXAM_PARAMS:
                return {
                    ...state,
                    loading: true,
                    saveParams: null,
                } 
            case INIT_GET_EXAMS_LIST:
            return {
                ...state,
                loading: true
            }        
        case INIT_GENERATE_MEDICAL_AGENDA:    
                return {
                    ...state,
                    loadingExams: true,
                }
        case INIT_SAVE_PHYSICAL_EXAM:    
                return {
                    ...state,
                    loadingSavesPhysical: true,
                }
        case GET_HEALTH_BENEFIT_ID:
            return {
                ...state,
                healthBenefit: action.payload,
                errorHealthBenefit: false,
                errorHealthBenefitMsg: null,
                loading: false,
            }    
        case ERROR_GET_HEALTH_BENEFIT_ID:
            return {
                ...state,
                healthBenefit: null,
                loading: false,
                errorHealthBenefitMsg: action.payload,
                errorHealthBenefit: true,
            }
        case SAVE_EXAM_PARAMS:
            return {
                ...state,
                loading: false,
                saveParams: true,
            }
        case ERROR_SAVE_EXAM_PARAMS: 
            return {
                ...state,
                loading: false,
                saveParams: false
            }
        case GET_HEALTH_BENEFITS_BY_PATIENT:
            return {
                ...state,
                loading: false,
                examList: action.payload,
                examListError: false
            }
        case ERROR_GET_HEALTH_BENEFITS_BY_PATIENT:
            return {
                ...state,
                loading: false,
                examList: null,
                examListError: true,
            } 
        case HEALTH_BENEFITS_UPDATE_LIST:
            return {
                ...state,
                loading: false,
                updateExamList: true
            }    
        case ERROR_HEALTH_BENEFITS_UPDATE_LIST:
            return {
                ...state,
                loading: false,
                updateExamList: false
            }
        case GENERATE_MEDICAL_AGENDA:
            return {
                ...state,
                loadingExams: false,
                isMedicalAgenda: true,
                medicalAgendaMsg: null,
            } 
        case ERROR_GENERATE_MEDICAL_AGENDA:
            return {
                ...state,
                loadingExams: false,
                isMedicalAgenda: false,
                medicalAgendaMsg: action.payload
            }
        case VALIDATE_MEDICAL_AGENDA:
            return {
                ...state,
                loading: false,
                isMedicalAgenda: true,
            } 
        case GET_EXAMS_LIST:
            return {
                ...state,
                loading: false,
                examList: action.payload.list,
                pdfOrder: action.payload.pdf,
                examListError: false,
            }    
        case ERROR_GET_EXAMS_LIST:
            return {
                ...state,
                loading: false,
                examList: null,
                pdfOrder: null,
                examListError: true,
            }         
        case ERROR_VALIDATE_MEDICAL_AGENDA:
            return {
                ...state,
                loading: false,
                isMedicalAgenda: false
            }    
        case SAVE_PHYSICAL_EXAM:
            return {
                ...state,
                loadingSavesPhysical: false,
                statusSavePhysicalExam: true,
                lastPhysicalExam: action.payload,

            }    
        case ERROR_SAVE_PHYSICAL_EXAM:
            return {
                ...state,
                loading: false,
                statusSavePhysicalExam: false,
                lastPhysicalExam: null,
            }   
        case GET_PROSTATE_ANTIGEN:
            return {
                ...state,
                loading: false,
                prostateAntigen: action.payload,
            }    
        case ERROR_GET_PROSTATE_ANTIGEN:
            return {
                ...state,
                loading: false,
                prostateAntigen: null,
            }    
        case SAVE_PROSTATE_ANTIGEN:
            return {
                ...state,
                loading: false,
                prostateAntigen: action.payload,
                errorProstateAntigen: false,
                isProstateAntigenStatusSave: true,
            }
        case ERROR_SAVE_PROSTATE_ANTIGEN:
            return {
                ...state,
                loading: false,
                prostateAntigen: null,
                errorProstateAntigen: true,
                isProstateAntigenStatusSave: false,
            }
        case GET_EXAM_RESULTS_HISTORY:
            return {
                ...state,
                loading: false,
                examResults: action.payload,
            }    
        case ERROR_GET_EXAM_RESULTS_HISTORY:
            return {
                ...state,
                loading: false,
                examResults: null,
            }    
        case GET_LAST_PHYSICAL_EXAM:
            return {
                ...state,
                loading: false,
                lastPhysicalExam: action.payload.lastExam,
            }   
        case ERROR_GET_LAST_PHYSICAL_EXAM:
            return {
                ...state,
                loading: false,
                lastPhysicalExam: null,
            }           
        case CLEAN_STATUS_EXAMS:
            return {
                ...state,
                saveParams: null,
                updateExamList: false,
                medicalAgendaMsg: null,
                statusSavePhysicalExam: null,
            }               
        default:
            return state;
    }
}    