import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import RoutesMap from 'happ-routes/RoutesMap';
import 'bootstrap/dist/css/bootstrap.min.css';
import store from "happ-store";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <RoutesMap />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
