import React from 'react';
import BloodPressureForm from 'happ-components/BloodPressureForm/BloodPressureForm';
import InfoPatientNav from 'happ-components/Navbar/InfoPatientNav';

const BloodPressure = () => {
    return ( 
        <>
            <InfoPatientNav />
            <BloodPressureForm 
                stage={'blood-pressure'}
            />
        </>
     );
}
 
export default BloodPressure;