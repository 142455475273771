import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DniTopsearch from 'happ-components/DniTopSearch/DniTopSearch';
import { Container, Row, Col, Form, Button} from 'react-bootstrap'
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getHealthBenefitsByPatientDniAction, getExamResultsHistoryAction } from 'happ-redux/happ-actions/exams/examsActions';
import ExamListParams from './ExamListParams';
const topDni = {
    dni: ''
}

const ExamList = () => {
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerChangeDni,
        handlerSubmit
    } = useValidatorForm(topDni, findExams, 'top-dni');
    const {dni} = state;
    const {examList, examListError, loading, pdfOrder, examResults} = useSelector(state => state.exams);
    function findExams() {
        dispatch(getHealthBenefitsByPatientDniAction(dni)); 
        dispatch(getExamResultsHistoryAction(dni)); 
    }
    return ( 
        <Container className="mt-5">
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <DniTopsearch 
                        state={state}
                        errors={errors}
                        handlerChangeDni={handlerChangeDni}
                        handlerSubmit={handlerSubmit}
                    />
                </Col>
            </Row>
            {!examListError && examList && examList.length && !loading ? (
                <>
                <Row className="justify-content-md-center mt-5">
                    <Col md={9} sm={9} lg={9} className="content-survey">
                        <Col md={12} sm={12} lg={12}>
                            <h4>Exámenes Médicos</h4>
                        </Col>
                            <Form>
                                <Row className='mb-3 header-info-doc'>
                                    {!examListError && examList ? examList.map(item => (
                                        <Col md={12} sm={12} lg={12} className="col-question" key={item._id}>
                                            <Form.Check
                                                inline
                                                className='check-survey'
                                                label={item.healthBenefit.name}
                                                value={true}
                                                name="q1"
                                                type="checkbox"
                                            />
                                        </Col>
                                    )) : null}
                                    <Col md={12} sm={12} lg={12} className="mt-3">
                                        <Button
                                            type='submit'
                                            className='btn btn-primary btn-survey'
                                        >Guardar</Button>
                                    </Col>   
                                </Row>        
                            </Form>
                    </Col>
                </Row>
                <Row className='justify-content-md-center mt-5'>
                    <Col md={9} sm={9} lg={9} className="content-survey">
                        {pdfOrder ? (
                            <div>
                                <a
                                    className='btn btn-primary text-center'
                                    href={pdfOrder.link}
                                    target="_blank"
                                >
                                    Descargar Orden Médica
                                </a>
                            </div>
                        ) : null}
                    </Col>
                </Row>
                </>
            ) : null}
            {examResults && examResults.length && !loading ? (
                <Row className="justify-content-md-center mt-5 mb-5">
                    <Col md={9} sm={9} lg={9} className="content-survey">
                        <Col md={12} sm={12} lg={12}>
                            <h4>Resultados de Exámenes</h4>
                        </Col>
                        {examResults.map(item => (
                            <Col md={12} sm={12} lg={12} className="col-question" key={item._id}>
                                <p>
                                    {item.exam}: 
                                    <ExamListParams 
                                        params={item.result}
                                    />
                                </p>
                            </Col>
                        ))}
                    </Col>
                </Row>    
            ) : null}
        </Container>    
    );
}
 
export default ExamList;