import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button, Spinner} from 'react-bootstrap';
import DniTopsearch from 'happ-components/DniTopSearch/DniTopSearch';
import BloodPressureFormTwo from 'happ-components/BloodPressureForm/BloodPressureFormTwo';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getBloodPressureInfoPatientAction } from 'happ-redux/happ-actions/patient-profile/patientProfileActions';

const topDni = {
    dni: ''
}

const BloodPressureTwo = () => {
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerChangeDni,
        handlerSubmit
    } = useValidatorForm(topDni, getInfoPatient, 'top-dni');
    const {dni} = state;
    const { patient, msgBloodPressureTwo, okAddBloodPressureTwo, loading } = useSelector(state => state.profile);
    function getInfoPatient() {
        dispatch(getBloodPressureInfoPatientAction(dni));
    }
    const handleClick = () => {
        window.location.href = '/segunda-toma-presion'
    }
    return ( 
        <>
            <Container className="mt-5">
                <h3 className='text-center mb-4'>Registro de segunda Toma de presión</h3>
                <Row className='mb-5'>
                    <Col xs={12} md={12} lg={12} className='text-center'>
                        {okAddBloodPressureTwo === null || (okAddBloodPressureTwo !== null && okAddBloodPressureTwo === false) ? (
                            <DniTopsearch 
                                state={state}
                                errors={errors}
                                handlerChangeDni={handlerChangeDni}
                                handlerSubmit={handlerSubmit}
                            />
                        ) : (
                            <>
                                <Button
                                    className='btn btn-primary btn-tp2 text-center'
                                    onClick={handleClick}
                                >
                                    Buscar otro paciente</Button>
                                <p className='mt-3'><strong>Rut: {dni}</strong></p>
                                <p className='mt-3'><strong>Nombre: {patient?.fullName}</strong></p>
                            </>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} className="content-spinner">
                        {okAddBloodPressureTwo === null && loading ? (
                            <Spinner 
                                animation="border"
                            />
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    {okAddBloodPressureTwo !== null && okAddBloodPressureTwo === true ? (
                        <>
                            <BloodPressureFormTwo 
                                stage={'blood-pressure-two'}
                            />
                        </>
                    ) : null}
                    {okAddBloodPressureTwo !== null && okAddBloodPressureTwo === false ? (
                        <Row style={{ maxWidth: '500px', margin: '0 auto' }} className="align-items-center justify-content-center">
                            <p className='error-msg'>{msgBloodPressureTwo}</p>
                        </Row>
                    ): null}
                </Row>
            </Container>
        </>
     );
}
 
export default BloodPressureTwo;