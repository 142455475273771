import {
    INIT_LOGIN,
    LOGIN,
    ERROR_LOGIN,
    LOGOUT,
    INIT_VALIDATE_SESSION,
    VALIDATE_SESSION,
    ERROR_VALIDATE_SESSION,
    INIT_SUMMARY_PATIENT,
    SUMMARY_PATIENT,
    ERROR_SUMMARY_PATIENT
} from 'happ-redux/happ-types';
import { adminAuth, verifyTokenSession, summaryPatient } from 'happ-redux/happ-services/auth/auth-service';

export function adminAuthAction(userParams, navigate) {
    const error = {
        server: 'Error en el servidor, no se puede acceder a la plataforma'
    }
    return async dispatch => {
        dispatch(initLogin());
        try {
            const response = await adminAuth(userParams);
            const { statusCode } = response.data;
            if (statusCode === 200) {
                dispatch(login(response.data));
                window.location.href = '/';
            }
            if (statusCode === 404) {
                dispatch(errorLogin(response.data.error));
            }
            if (statusCode === 400 || statusCode === 500) {
                dispatch(errorLogin(error));
            }
        } catch (errorMsg) {
            dispatch(errorLogin(error));
        }
    }
}

export function isLoginAction(navigate) {
    return async dispatch => {
        dispatch(initValidateSession());
        try {
            const token = localStorage.getItem('token');
            if (token) {
                const response = await verifyTokenSession(token);
                const { statusCode } = response.data;
                if (statusCode === 200) {
                    dispatch(session(response.data));
                } else {
                    dispatch(errorValidataSession());
                    navigate('/login');
                }
            } else {
                dispatch(errorValidataSession());
                navigate('/login');
            }
        } catch (error) {
            dispatch(errorValidataSession());
            navigate('/login');
        }
    }
}

export function summaryPatientAction(patient, operative, medicalStage, isHypertensive, navigate) {
    return async dispatch => {
        dispatch(initSummaryPatient());
        try {
            const token = localStorage.getItem('token');
            const response = await summaryPatient(token, patient, medicalStage, isHypertensive, operative);
            if (response.data.status === 200) {
                dispatch(summaryPatientInfo(response.data));
            } else {
                dispatch(errorSummaryPatient(true));
            }
        } catch (error) {
            dispatch(errorSummaryPatient(true));
        }
    }
}

export function signOffAction(navigate) {
    return async (dispatch) => {
        dispatch(logout());
        navigate('/login');
    }
}

const initSummaryPatient = () => ({
    type: INIT_SUMMARY_PATIENT,
});

const summaryPatientInfo = response => ({
    type: SUMMARY_PATIENT,
    payload: response,
});

const errorSummaryPatient = error => ({
    type: ERROR_SUMMARY_PATIENT,
    payload: error,
});

const initLogin = () => ({
    type: INIT_LOGIN
});

const login = response => ({
    type: LOGIN,
    payload: response
});

const errorLogin = error => ({
    type: ERROR_LOGIN,
    payload: error
});

const logout = () => ({
    type: LOGOUT
});

const initValidateSession = () => ({
    type: INIT_VALIDATE_SESSION
});

const session = response => ({
    type: VALIDATE_SESSION,
    payload: response
});

const errorValidataSession = () => ({
    type: ERROR_VALIDATE_SESSION
});