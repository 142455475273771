import { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
const LastResultExam = ({origin, result}) => {
    const [exam, setExam] = useState([]);
    const filterResultsByOrigin = (origin) => {
        if (result.length) {
            const filterExam = result.filter(item => item.exam === origin);
            setExam(filterExam);
        }
    }
    useEffect(() => {
      filterResultsByOrigin(origin);
    }, [result, origin])
    
    return (
        <>
            {exam.length ? (
                <Col className="content-form" sm={7} md={7} lg={7}>
                    <h4>Ultimo resultado del dia de hoy:</h4><br></br>
                    {
                        origin === 'Toma de presion 1' ? (
                            <>
                                <p>Sistolica: <strong>{exam[0].results.sistolica} mmhg</strong></p>
                                <p>Diastolica: <strong>{exam[0].results.diastolica} mmhg</strong></p>
                            </>
                        ) :
                        origin === 'Hemoglucotest' ? (
                            <>
                                <p>Glicemia capilar : <strong>{exam[0].results.glicemia} mg/dl</strong></p>
                                <p>En ayuno ? : <strong>{exam[0].results.fast ? 'SI' : 'NO'}</strong></p>
                            </>
                        ) :
                        origin === 'Estudio del peso' ? (
                            <>
                                <p>Peso <strong>{exam[0].results.weight} kg</strong></p>
                                <p>Altura <strong>{exam[0].results.height} cm</strong></p>
                                <p>Circunferencia <strong>{exam[0].results.circumference} cm</strong></p> 
                                <p>IMC <strong>{exam[0].results.imc}</strong></p> 
                            </>
                        ) : null
                    }
                </Col>
            ) : null}
        </>
    );
}
 
export default LastResultExam;