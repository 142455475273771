//session
    export const INIT_LOGIN = 'INIT_LOGIN';
    export const LOGIN = 'LOGIN';
    export const ERROR_LOGIN = 'ERROR_LOGIN';
    export const LOGOUT = 'LOGOUT';

    export const INIT_VALIDATE_SESSION = 'INIT_VALIDATE_SESSION';
    export const VALIDATE_SESSION = 'VALIDATE_SESSION';
    export const ERROR_VALIDATE_SESSION = 'ERROR_VALIDATE_SESSION';
    export const ERROR_TOKEN = 'ERROR_TOKEN';

    export const INIT_GET_EXAMS_LIST = 'INIT_GET_EXAMS_LIST';
    export const GET_EXAMS_LIST = 'GET_EXAMS_LIST';
    export const ERROR_GET_EXAMS_LIST = 'ERROR_GET_EXAMS_LIST';
    
    // patient

        export const INIT_CREATE_PATIENT = 'INIT_CREATE_PATIENT';
        export const CREATE_PATIENT = 'CREATE_PATIENT';
        export const ERROR_CREATE_PATIENT = 'ERROR_CREATE_PATIENT';

        //profile
        export const INIT_PATIENT_PROFILE = 'INIT_PATIENT_PROFILE';
        export const PATIENT_PROFILE = 'PATIENT_PROFILE';
        export const ERROR_PATIENT_PROFILE = 'ERROR_PATIENT_PROFILE';
        export const INIT_UPDATE_PROFILE = 'INIT_UPDATE_PROFILE';
        export const UPDATE_PROFILE = 'UPDATE_PROFILE';
        export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
        export const CLEAN_STATUS_PROFILE = 'CLEAN_STATUS_PROFILE';
        export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';

        //survey
        export const INIT_SAVE_SURVEY = 'INIT_SAVE_SURVEY';
        export const SAVE_SURVEY = 'SAVE_SURVEY';
        export const ERROR_SAVE_SURVEY = 'ERROR_SAVE_SURVEY';
        export const CLEAN_STATUS_SURVEY = 'CLEAN_STATUS_SURVEY';

        //health benefits
        export const INIT_GET_HEALTH_BENEFIT_ID = 'INIT_GET_HEALTH_BENEFIT_ID';
        export const GET_HEALTH_BENEFIT_ID = 'GET_HEALTH_BENEFIT_ID';
        export const ERROR_GET_HEALTH_BENEFIT_ID = 'ERROR_GET_HEALTH_BENEFIT_ID';
        export const INIT_SAVE_EXAM_PARAMS = 'INIT_SAVE_EXAM_PARAMS';
        export const SAVE_EXAM_PARAMS = 'SAVE_EXAM_PARAMS';
        export const ERROR_SAVE_EXAM_PARAMS = 'ERROR_SAVE_EXAM_PARAMS';
        export const CLEAN_STATUS_EXAMS = 'CLEAN_STATUS_EXAMS';
        export const INIT_GET_HEALTH_BENEFITS_BY_PATIENT = 'INIT_GET_HEALTH_BENEFITS_BY_PATIENT';
        export const GET_HEALTH_BENEFITS_BY_PATIENT = 'GET_HEALTH_BENEFITS_BY_PATIENT';
        export const ERROR_GET_HEALTH_BENEFITS_BY_PATIENT = 'ERROR_GET_HEALTH_BENEFITS_BY_PATIENT';
        export const INIT_HEALTH_BENEFITS_UPDATE_LIST = 'INIT_HEALTH_BENEFITS_UPDATE_LIST';
        export const HEALTH_BENEFITS_UPDATE_LIST = 'HEALTH_BENEFITS_UPDATE_LIST';
        export const ERROR_HEALTH_BENEFITS_UPDATE_LIST = 'ERROR_HEALTH_BENEFITS_UPDATE_LIST';
        export const INIT_GENERATE_MEDICAL_AGENDA = 'INIT_GENERATE_MEDICAL_AGENDA';
        export const GENERATE_MEDICAL_AGENDA = 'GENERATE_MEDICAL_AGENDA';
        export const ERROR_GENERATE_MEDICAL_AGENDA = 'ERROR_GENERATE_MEDICAL_AGENDA';
        export const INIT_VALIDATE_MEDICAL_AGENDA = 'INIT_VALIDATE_MEDICAL_AGENDA';
        export const VALIDATE_MEDICAL_AGENDA = 'VALIDATE_MEDICAL_AGENDA';
        export const ERROR_VALIDATE_MEDICAL_AGENDA = 'ERROR_VALIDATE_MEDICAL_AGENDA';
        export const INIT_GET_LAST_PHYSICAL_EXAM = 'INIT_GET_LAST_PHYSICAL_EXAM'; 
        export const GET_LAST_PHYSICAL_EXAM = 'GET_LAST_PHYSICAL_EXAM'; 
        export const ERROR_GET_LAST_PHYSICAL_EXAM = 'ERROR_GET_LAST_PHYSICAL_EXAM'; 
        export const INIT_GET_BLOODPRESSURE_INFO = 'INIT_GET_BLOODPRESSURE_INFO'; 
        export const GET_BLOODPRESSURE_INFO = 'GET_BLOODPRESSURE_INFO'; 
        export const ERROR_GET_BLOODPRESSURE_INFO = 'ERROR_GET_BLOODPRESSURE_INFO'; 

        //box
        export const INIT_GET_BOXES = 'INIT_GET_BOXES';
        export const GET_BOXES = 'GET_BOXES';
        export const ERROR_GET_BOXES = 'ERROR_GET_BOXES';
        export const INIT_ASSIGN_BOX_TO_PATIENT = 'INIT_ASSIGN_BOX_TO_PATIENT';
        export const ASSIGN_BOX_TO_PATIENT = 'ASSIGN_BOX_TO_PATIENT';
        export const ERROR_ASSIGN_BOX_TO_PATIENT = 'ERROR_ASSIGN_BOX_TO_PATIENT';
        export const CLEAR_STATUS_BOX = 'CLEAR_STATUS_BOX';

        //Imed Huellero
        export const INIT_VERIFICATE_DNI_IMED = 'INIT_VERIFICATE_DNI_IMED'
        export const VERIFICATE_DNI_IMED = 'VERIFICATE_DNI_IMED';
        export const VERIFICATE_DNI_IMED_ERROR = 'VERIFICATE_DNI_IMED_ERROR';

        export const INIT_CERTIFICATION_DNI_IMED = 'INIT_CERTIFICATION_DNI_IMED'
        export const CERTIFICATION_DNI_IMED = 'CERTIFICATION_DNI_IMED';
        export const CERTIFICATION_DNI_IMED_ERROR = 'CERTIFICATION_DNI_IMED_ERROR';

        //Patient List
        export const INIT_GET_PATIENT_LIST = 'INIT_GET_PATIENT_LIST';
        export const GET_PATIENT_LIST = 'GET_PATIENT_LIST';
        export const ERROR_GET_PATIENT_LIST = 'ERROR_GET_PATIENT_LIST';

        //Summary Patient Results
        export const INIT_SUMMARY_PATIENT = 'INIT_SUMMARY_PATIENT';
        export const SUMMARY_PATIENT = 'SUMMARY_PATIENT';
        export const ERROR_SUMMARY_PATIENT = 'ERROR_SUMMARY_PATIENT';

        export const INIT_SAVE_PHYSICAL_EXAM = 'INIT_SAVE_PHYSICAL_EXAM'; 
        export const SAVE_PHYSICAL_EXAM = 'SAVE_PHYSICAL_EXAM'; 
        export const ERROR_SAVE_PHYSICAL_EXAM = 'ERROR_SAVE_PHYSICAL_EXAM'; 

        export const INIT_GET_COMPANIES = 'INIT_GET_COMPANIES'
        export const GET_COMPANIES = 'GET_COMPANIES'
        export const ERROR_GET_COMPANIES = 'ERROR_GET_COMPANIES'
        export const INIT_GET_BRANCHES = 'INIT_GET_BRANCHES'
        export const GET_BRANCHES = 'GET_BRANCHES'
        export const ERROR_GET_BRANCHES = 'ERROR_GET_BRANCHES'

        export const INIT_GET_EXAM_RESULTS_HISTORY = 'INIT_GET_EXAM_RESULTS_HISTORY';
        export const GET_EXAM_RESULTS_HISTORY = 'GET_EXAM_RESULTS_HISTORY';
        export const ERROR_GET_EXAM_RESULTS_HISTORY = 'ERROR_GET_EXAM_RESULTS_HISTORY';

        //Consent Signature
        export const INI_GET_STATUS_CONSENT_SIGNATURE = 'INI_GET_STATUS_CONSENT_SIGNATURE';
        export const GET_STATUS_CONSENT_SIGNATURE = 'GET_STATUS_CONSENT_SIGNATURE';
        export const ERROR_GET_STATUS_CONSENT_SIGNATURE = 'ERROR_GET_STATUS_CONSENT_SIGNATURE';

        export const INI_GET_PDF_SIGNED_CONSENT_SIGNATURE = 'INI_GET_PDF_SIGNED_CONSENT_SIGNATURE';
        export const GET_PDF_SIGNED_CONSENT_SIGNATURE = 'GET_PDF_SIGNED_CONSENT_SIGNATURE';
        export const ERROR_GET_PDF_SIGNED_CONSENT_SIGNATURE = 'ERROR_GET_PDF_SIGNED_CONSENT_SIGNATURE';

        export const INIT_GET_PDF_PREVIEW_CONSENT_SIGNATURE = 'INIT_GET_PDF_PREVIEW_CONSENT_SIGNATURE';
        export const GET_PDF_PREVIEW_CONSENT_SIGNATURE = 'GET_PDF_PREVIEW_CONSENT_SIGNATURE';
        export const ERROR_GET_PDF_PREVIEW_CONSENT_SIGNATURE = 'ERROR_GET_PDF_PREVIEW_CONSENT_SIGNATURE';

        export const INIT_ACCEPT_TERMS = 'INIT_ACCEPT_TERMS';
        export const ACCEPT_TERMS = 'ACCEPT_TERMS';
        export const ERROR_ACCEPT_TERMS = 'ERROR_ACCEPT_TERMS';

        export const INIT_CONFIRM_TERMS = 'INIT_CONFIRM_TERMS';
        export const CONFIRM_TERMS = 'CONFIRM_TERMS';
        export const ERROR_CONFIRM_TERMS = 'ERROR_CONFIRM_TERMS';

        export const INIT_CONFIRM_PAPER_TERMS = 'INIT_CONFIRM_PAPER_TERMS';
        export const CONFIRM_PAPER_TERMS = 'CONFIRM_PAPER_TERMS';
        export const ERROR_CONFIRM_PAPER_TERMS = 'ERROR_CONFIRM_PAPER_TERMS';

        export const CLEAN_CONSET_SIGNATURE = 'CLEAN_CONSET_SIGNATURE';

        //ProstateAntigen
        export const INIT_SAVE_PROSTATE_ANTIGEN = 'INIT_SAVE_PROSTATE_ANTIGEN';
        export const SAVE_PROSTATE_ANTIGEN = 'SAVE_PROSTATE_ANTIGEN';
        export const ERROR_SAVE_PROSTATE_ANTIGEN = 'ERROR_SAVE_PROSTATE_ANTIGEN';
        export const INIT_GET_PROSTATE_ANTIGEN = 'INIT_GET_PROSTATE_ANTIGEN';
        export const GET_PROSTATE_ANTIGEN = 'GET_PROSTATE_ANTIGEN';
        export const ERROR_GET_PROSTATE_ANTIGEN = 'ERROR_GET_PROSTATE_ANTIGEN';

