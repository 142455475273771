import { Row, Button } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';
const ConsentSignatureOk = ({showAlertPdf, getSignedPdf}) => {
    return ( 
        <Row>
            <div className='d-flex justify-content-center'>
                <div className='mt-5'>
                    <p className='status-consent-signature'>
                        Estado: 
                        <strong>Consentimiento firmado</strong>
                        <CheckCircleFill 
                            className='icon-check-signature'
                        />
                    </p>
                    <p className='status-consent-signature'>
                        Tipo: 
                        <strong>Digital</strong>
                    </p>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <div className='mt-3'>
                    <Button
                        className='btn btn-primary btn-dwl-consent-signature'
                        onClick={getSignedPdf}
                    >
                        Ver consentimiento firmado
                    </Button>
                </div>
            </div>
        </Row>
     );
}
 
export default ConsentSignatureOk;