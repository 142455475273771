import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {   
    Routes,
    Route,
    Navigate,
    useNavigate } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";

import { isLoginAction } from 'happ-redux/happ-actions/auth/authActions';

//Views
import Enrollment from 'happ-views/shared-views/Enrollment/Enrollment';
import Login from 'happ-views/login/Login';
import Home from 'happ-views/Home';
import PatientList from 'happ-views/shared-views/PatientList/PatientList';
import Navbar from 'happ-components/Navbar/Navbar';
import ExamList from 'happ-views/shared-views/ExamList/ExamList';
import TemplateProfile from 'happ-views/TemplateProfile';
import BloodPressureTwo from 'happ-views/shared-views/BloodPressureTwo/BloodPressureTwo';

const RoutesMap = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ProtectedRoute = ({ redirectPath = '/login', children }) => {
        useEffect(() => {
            dispatch(isLoginAction(navigate));
        }, []);
        return localStorage.getItem("token") !== null ? (
            <Row className="row-nav"> 
                <Col xs={12}>
                    <Navbar />
                    {children}
                </Col> 
            </Row>
        ): (
            <Navigate to={redirectPath} replace />
        );
    };
    const RoutesWithoutNavBar = ({ children }) => {
        return children;
    }

    return ( 
        <Routes>
            <Route 
                path="/validar-identidad"
                element={
                    <ProtectedRoute>
                        <Enrollment />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/"
                element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/listado-examenes" 
                element={
                    <ProtectedRoute>
                        <ExamList />
                    </ProtectedRoute>
                } 
            />
            <Route 
                path="/nuevo-paciente" 
                element={
                    <ProtectedRoute>
                        <TemplateProfile />
                    </ProtectedRoute>
                } 
            />
            <Route 
                path="/listado-operativo" 
                element={
                    <ProtectedRoute>
                        <PatientList />
                    </ProtectedRoute>
                } 
            />
            <Route 
                path="/segunda-toma-presion" 
                element={
                    <ProtectedRoute>
                        <BloodPressureTwo />
                    </ProtectedRoute>
                } 
            />
            <Route 
                path="/login" 
                element={
                    <RoutesWithoutNavBar>
                        <Login />
                    </RoutesWithoutNavBar>
                } 
            />
            <Route 
                path="*" 
                element={<p>There's nothing here: 404!</p>} 
            />
        </Routes>
     );
}
 
export default RoutesMap;