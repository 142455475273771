import axiosClient from 'happ-config/axios';


const getPatientList = async (token, operatives) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post(`/patient-scheduling/get-patient-scheduling-list-by-operative-today`, operatives, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getPatientList,
}