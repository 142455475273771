import axiosClient from 'happ-config/axios';

const verificateDniImed = async dni => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get(`enrollment/imed-verification/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const certificateDniImed = async body => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.post(`enrollment/imed-certification`, body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    verificateDniImed,
    certificateDniImed
}