/* 

    Obtener regiones y comunas de base de datos y guardarlos en objetos.
*/
const regiones = [
    { id: 15, name: 'Arica y Parinacota' },
    { id: 1, name: 'Tarapacá' },
    { id: 2, name: 'Antofagasta' },
    { id: 3, name: 'Atacama' },
    { id: 4, name: 'Coquimbo' },
    { id: 5, name: 'Valparaiso' },
    { id: 13, name: 'Metropolitana de Santiago' },
    { id: 6, name: 'Libertador General Bernardo O\'Higgins' },
    { id: 7, name: 'Maule' },
    { id: 8, name: 'Biobío' },
    { id: 9, name: 'La Araucanía' },
    { id: 14, name: 'Los Ríos' },
    { id: 10, name: 'Los Lagos' },
    { id: 11, name: 'Aisén del General Carlos Ibáñez del Campo' },
    { id: 12, name: 'Magallanes y de la Antártica Chilena' }
];
const comunas = [
    { id: 296, name:'CAMINA', reg: 1 },
    { id: 297, name:'COLCHANE', reg: 1 },
    { id: 3, name:'HUARA', reg: 1 },
    { id: 2, name:'IQUIQUE', reg: 1 },
    { id: 4, name:'PICA', reg: 1 },
    { id: 5, name:'POZO ALMONTE', reg: 1 },
    { id: 7, name:'ANTOFAGASTA', reg: 2 },
    { id: 10, name:'CALAMA', reg: 2 },
    { id: 298, name:'MARIA ELENA', reg: 2 },
    { id: 8, name:'MEJILLONES', reg: 2 },
    { id: 300, name:'OLLAGÃœE', reg: 2 },
    { id: 301, name:'SAN PEDRO DE ATACAMA', reg: 2 },
    { id: 299, name:'SIERRA GORDA', reg: 2 },
    { id: 9, name:'TALTAL', reg: 2 },
    { id: 6, name:'TOCOPILLA', reg: 2 },
    { id: 302, name:'ALTO DEL CARMEN', reg: 3 },
    { id: 14, name:'CALDERA', reg: 3 },
    { id: 11, name:'CHAÑARAL', reg: 3 },
    { id: 13, name:'COPIAPO', reg: 3 },
    { id: 12, name:'DIEGO DE ALMAGRO', reg: 3 },
    { id: 17, name:'FREIRINA', reg: 3 },
    { id: 18, name:'HUASCO', reg: 3 },
    { id: 15, name:'TIERRA AMARILLA', reg: 3 },
    { id: 16, name:'VALLENAR', reg: 3 },
    { id: 22, name:'ANDACOLLO', reg: 4 },
    { id: 31, name:'CANELA', reg: 4 },
    { id: 29, name:'COMBARBALA', reg: 4 },
    { id: 21, name:'COQUIMBO', reg: 4 },
    { id: 30, name:'ILLAPEL', reg: 4 },
    { id: 20, name:'LA HIGUERA', reg: 4 },
    { id: 19, name:'LA SERENA', reg: 4 },
    { id: 33, name:'LOS VILOS', reg: 4 },
    { id: 26, name:'MONTE PATRIA', reg: 4 },
    { id: 25, name:'OVALLE', reg: 4 },
    { id: 24, name:'PAIHUANO', reg: 4 },
    { id: 27, name:'PUNITAQUI', reg: 4 },
    { id: 28, name:'RIO HURTADO', reg: 4 },
    { id: 32, name:'SALAMANCA', reg: 4 },
    { id: 23, name:'VICUÑA', reg: 4 },
    { id: 44, name:'ALGARROBO', reg: 5 },
    { id: 56, name:'CABILDO', reg: 5 },
    { id: 67, name:'CALLE LARGA', reg: 5 },
    { id: 46, name:'CARTAGENA', reg: 5 },
    { id: 40, name:'CASABLANCA', reg: 5 },
    { id: 63, name:'CATEMU', reg: 5 },
    { id: 340, name:'CONCON', reg: 5 },
    { id: 47, name:'EL TABO', reg: 5 },
    { id: 51, name:'HIJUELAS', reg: 5 },
    { id: 41, name:'ISLA DE PASCUA', reg: 5 },
    { id: 321, name:'JUAN FERNANDEZ', reg: 5 },
    { id: 50, name:'LA CALERA', reg: 5 },
    { id: 49, name:'LA CRUZ', reg: 5 },
    { id: 59, name:'LA LIGUA', reg: 5 },
    { id: 53, name:'LIMACHE', reg: 5 },
    { id: 65, name:'LLAY LLAY', reg: 5 },
    { id: 66, name:'LOS ANDES', reg: 5 },
    { id: 52, name:'NOGALES', reg: 5 },
    { id: 54, name:'OLMUE', reg: 5 },
    { id: 62, name:'PANQUEHUE', reg: 5 },
    { id: 57, name:'PAPUDO', reg: 5 },
    { id: 55, name:'PETORCA', reg: 5 },
    { id: 36, name:'PUCHUNCAVI', reg: 5 },
    { id: 61, name:'PUTAENDO', reg: 5 },
    { id: 48, name:'QUILLOTA', reg: 5 },
    { id: 38, name:'QUILPUE', reg: 5 },
    { id: 35, name:'QUINTERO', reg: 5 },
    { id: 68, name:'RINCONADA', reg: 5 },
    { id: 42, name:'SAN ANTONIO', reg: 5 },
    { id: 69, name:'SAN ESTEBAN', reg: 5 },
    { id: 60, name:'SAN FELIPE', reg: 5 },
    { id: 64, name:'SANTA MARIA', reg: 5 },
    { id: 43, name:'SANTO DOMINGO', reg: 5 },
    { id: 34, name:'VALPARAISO', reg: 5 },
    { id: 39, name:'VILLA ALEMANA', reg: 5 },
    { id: 37, name:'VIÑA DEL MAR', reg: 5 },
    { id: 58, name:'ZAPALLAR', reg: 5 },
    { id: 132, name:'CHEPICA', reg: 6 },
    { id: 125, name:'CHIMBARONGO', reg: 6 },
    { id: 110, name:'CODEGUA', reg: 6 },
    { id: 114, name:'COINCO', reg: 6 },
    { id: 113, name:'COLTAUCO', reg: 6 },
    { id: 112, name:'DOÑIHUE', reg: 6 },
    { id: 107, name:'GRANEROS', reg: 6 },
    { id: 139, name:'LA ESTRELLA', reg: 6 },
    { id: 116, name:'LAS CABRAS', reg: 6 },
    { id: 136, name:'LITUECHE', reg: 6 },
    { id: 129, name:'LOLOL', reg: 6 },
    { id: 106, name:'MACHALI', reg: 6 },
    { id: 122, name:'MALLOA', reg: 6 },
    { id: 134, name:'MARCHIGUE', reg: 6 },
    { id: 126, name:'NANCAGUA', reg: 6 },
    { id: 138, name:'NAVIDAD', reg: 6 },
    { id: 130, name:'PALMILLA', reg: 6 },
    { id: 133, name:'PAREDONES', reg: 6 },
    { id: 131, name:'PERALILLO', reg: 6 },
    { id: 115, name:'PEUMO', reg: 6 },
    { id: 118, name:'PICHIDEGUA', reg: 6 },
    { id: 137, name:'PICHILEMU', reg: 6 },
    { id: 127, name:'PLACILLA', reg: 6 },
    { id: 135, name:'PUMANQUE', reg: 6 },
    { id: 123, name:'QUINTA DE TILCOCO', reg: 6 },
    { id: 105, name:'RANCAGUA', reg: 6 },
    { id: 121, name:'RENGO', reg: 6 },
    { id: 119, name:'REQUINOA', reg: 6 },
    { id: 124, name:'SAN FERNANDO', reg: 6 },
    { id: 111, name:'SAN FRANCISCO DE MOSTAZAL', reg: 6 },
    { id: 117, name:'SAN VICENTE', reg: 6 },
    { id: 128, name:'SANTA CRUZ', reg: 6 },
    { id: 166, name:'CAUQUENES', reg: 7 },
    { id: 167, name:'CHANCO', reg: 7 },
    { id: 161, name:'COLBUN', reg: 7 },
    { id: 157, name:'CONSTITUCION', reg: 7 },
    { id: 155, name:'CUREPTO', reg: 7 },
    { id: 140, name:'CURICO', reg: 7 },
    { id: 158, name:'EMPEDRADO', reg: 7 },
    { id: 144, name:'HUALAÑE', reg: 7 },
    { id: 145, name:'LICANTEN', reg: 7 },
    { id: 159, name:'LINARES', reg: 7 },
    { id: 162, name:'LONGAVI', reg: 7 },
    { id: 154, name:'MAULE', reg: 7 },
    { id: 147, name:'MOLINA', reg: 7 },
    { id: 164, name:'PARRAL', reg: 7 },
    { id: 152, name:'PELARCO', reg: 7 },
    { id: 320, name:'PELLUHUE', reg: 7 },
    { id: 153, name:'PENCAHUE', reg: 7 },
    { id: 143, name:'RAUCO', reg: 7 },
    { id: 165, name:'RETIRO', reg: 7 },
    { id: 149, name:'RIO CLARO', reg: 7 },
    { id: 141, name:'ROMERAL', reg: 7 },
    { id: 148, name:'SAGRADA FAMILIA', reg: 7 },
    { id: 151, name:'SAN CLEMENTE', reg: 7 },
    { id: 156, name:'SAN JAVIER', reg: 7 },
    { id: 341, name:'SAN RAFAEL', reg: 7 },
    { id: 150, name:'TALCA', reg: 7 },
    { id: 142, name:'TENO', reg: 7 },
    { id: 146, name:'VICHUQUEN', reg: 7 },
    { id: 163, name:'VILLA ALEGRE', reg: 7 },
    { id: 303, name:'ANTUCO', reg: 8 },
    { id: 198, name:'ARAUCO', reg: 8 },
    { id: 180, name:'BULNES', reg: 8 },
    { id: 208, name:'CABRERO', reg: 8 },
    { id: 201, name:'CAÑETE', reg: 8 },
    { id: 344, name:'CHIGUAYANTE', reg: 8 },
    { id: 168, name:'CHILLAN', reg: 8 },
    { id: 342, name:'CHILLAN VIEJO', reg: 8 },
    { id: 175, name:'COBQUECURA', reg: 8 },
    { id: 186, name:'COELEMU', reg: 8 },
    { id: 170, name:'COIHUECO', reg: 8 },
    { id: 188, name:'CONCEPCION', reg: 8 },
    { id: 202, name:'CONTULMO', reg: 8 },
    { id: 194, name:'CORONEL', reg: 8 },
    { id: 197, name:'CURANILAHUE', reg: 8 },
    { id: 185, name:'EL CARMEN', reg: 8 },
    { id: 193, name:'FLORIDA', reg: 8 },
    { id: 192, name:'HUALQUI', reg: 8 },
    { id: 210, name:'LAJA', reg: 8 },
    { id: 199, name:'LEBU', reg: 8 },
    { id: 200, name:'LOS ALAMOS', reg: 8 },
    { id: 204, name:'LOS ANGELES', reg: 8 },
    { id: 195, name:'LOTA', reg: 8 },
    { id: 214, name:'MULCHEN', reg: 8 },
    { id: 212, name:'NACIMIENTO', reg: 8 },
    { id: 213, name:'NEGRETE', reg: 8 },
    { id: 174, name:'NINHUE', reg: 8 },
    { id: 184, name:'PEMUCO', reg: 8 },
    { id: 191, name:'PENCO', reg: 8 },
    { id: 169, name:'PINTO', reg: 8 },
    { id: 171, name:'PORTEZUELO', reg: 8 },
    { id: 215, name:'QUILACO', reg: 8 },
    { id: 206, name:'QUILLECO', reg: 8 },
    { id: 182, name:'QUILLON', reg: 8 },
    { id: 172, name:'QUIRIHUE', reg: 8 },
    { id: 187, name:'RANQUIL', reg: 8 },
    { id: 176, name:'SAN CARLOS', reg: 8 },
    { id: 178, name:'SAN FABIAN', reg: 8 },
    { id: 177, name:'SAN GREGORIO DE Ã‘IQUEN', reg: 8 },
    { id: 181, name:'SAN IGNACIO', reg: 8 },
    { id: 179, name:'SAN NICOLAS', reg: 8 },
    { id: 343, name:'SAN PEDRO DE LA PAZ', reg: 8 },
    { id: 211, name:'SAN ROSENDO', reg: 8 },
    { id: 205, name:'SANTA BARBARA', reg: 8 },
    { id: 196, name:'SANTA JUANA', reg: 8 },
    { id: 203, name:'TIRUA', reg: 8 },
    { id: 190, name:'TOME', reg: 8 },
    { id: 173, name:'TREHUACO', reg: 8 },
    { id: 209, name:'TUCAPEL', reg: 8 },
    { id: 207, name:'YUMBEL', reg: 8 },
    { id: 183, name:'YUNGAY', reg: 8 },
    { id: 216, name:'ANGOL', reg: 9 },
    { id: 235, name:'CARAHUE', reg: 9 },
    { id: 220, name:'COLLIPULLI', reg: 9 },
    { id: 230, name:'CUNCO', reg: 9 },
    { id: 225, name:'CURACAUTIN', reg: 9 },
    { id: 305, name:'CURARREHUE', reg: 9 },
    { id: 221, name:'ERCILLA', reg: 9 },
    { id: 229, name:'FREIRE', reg: 9 },
    { id: 232, name:'GALVARINO', reg: 9 },
    { id: 238, name:'GORBEA', reg: 9 },
    { id: 231, name:'LAUTARO', reg: 9 },
    { id: 240, name:'LONCOCHE', reg: 9 },
    { id: 226, name:'LONQUIMAY', reg: 9 },
    { id: 218, name:'LOS SAUCES', reg: 9 },
    { id: 223, name:'LUMACO', reg: 9 },
    { id: 304, name:'MELIPEUCO', reg: 9 },
    { id: 234, name:'NUEVA IMPERIAL', reg: 9 },
    { id: 345, name:'PADRE LAS CASAS', reg: 9 },
    { id: 233, name:'PERQUENCO', reg: 9 },
    { id: 237, name:'PITRUFQUEN', reg: 9 },
    { id: 242, name:'PUCON', reg: 9 },
    { id: 236, name:'PUERTO SAAVEDRA', reg: 9 },
    { id: 217, name:'PUREN', reg: 9 },
    { id: 219, name:'RENAICO', reg: 9 },
    { id: 227, name:'TEMUCO', reg: 9 },
    { id: 306, name:'TEODORO SCHMIDT', reg: 9 },
    { id: 239, name:'TOLTEN', reg: 9 },
    { id: 222, name:'TRAIGUEN', reg: 9 },
    { id: 224, name:'VICTORIA', reg: 9 },
    { id: 228, name:'VILCUN', reg: 9 },
    { id: 241, name:'VILLARRICA', reg: 9 },
    { id: 277, name:'ANCUD', reg: 10 },
    { id: 265, name:'CALBUCO', reg: 10 },
    { id: 270, name:'CASTRO', reg: 10 },
    { id: 280, name:'CHAITEN', reg: 10 },
    { id: 271, name:'CHONCHI', reg: 10 },
    { id: 262, name:'COCHAMO', reg: 10 },
    { id: 276, name:'CURACO DE VELEZ', reg: 10 },
    { id: 279, name:'DALCAHUE', reg: 10 },
    { id: 268, name:'FRESIA', reg: 10 },
    { id: 281, name:'FUTALEUFU', reg: 10 },
    { id: 308, name:'HUALAIHUE', reg: 10 },
    { id: 267, name:'LLANQUIHUE', reg: 10 },
    { id: 264, name:'LOS MUERMOS', reg: 10 },
    { id: 263, name:'MAULLIN', reg: 10 },
    { id: 255, name:'OSORNO', reg: 10 },
    { id: 282, name:'PALENA', reg: 10 },
    { id: 261, name:'PUERTO MONTT', reg: 10 },
    { id: 258, name:'PUERTO OCTAY', reg: 10 },
    { id: 266, name:'PUERTO VARAS', reg: 10 },
    { id: 274, name:'PUQUELDON', reg: 10 },
    { id: 260, name:'PURRANQUE', reg: 10 },
    { id: 256, name:'PUYEHUE', reg: 10 },
    { id: 272, name:'QUEILEN', reg: 10 },
    { id: 273, name:'QUELLON', reg: 10 },
    { id: 278, name:'QUEMCHI', reg: 10 },
    { id: 275, name:'QUINCHAO', reg: 10 },
    { id: 259, name:'RIO NEGRO', reg: 10 },
    { id: 307, name:'SAN JUAN DE LA COSTA', reg: 10 },
    { id: 257, name:'SAN PABLO', reg: 10 },
    { id: 285, name:'AYSEN', reg: 11 },
    { id: 287, name:'CHILE CHICO', reg: 11 },
    { id: 286, name:'CISNES', reg: 11 },
    { id: 289, name:'COCHRANE', reg: 11 },
    { id: 284, name:'COYHAIQUE', reg: 11 },
    { id: 309, name:'GUAITECAS', reg: 11 },
    { id: 312, name:'LAGO VERDE', reg: 11 },
    // { id: 310, name:'OÂ´HIGGINS', reg: 11 },
    { id: 288, name:'RIO IBAÃÑEZ', reg: 11 },
    { id: 311, name:'TORTEL', reg: 11 },
    { id: 316, name:'LAGUNA BLANCA', reg: 12 },
    { id: 319, name:'NAVARINO', reg: 12 },
    { id: 292, name:'PORVENIR', reg: 12 },
    { id: 317, name:'PRIMAVERA', reg: 12 },
    { id: 291, name:'PUERTO NATALES', reg: 12 },
    { id: 290, name:'PUNTA ARENAS', reg: 12 },
    { id: 314, name:'RIO VERDE', reg: 12 },
    { id: 315, name:'SAN GREGORIO', reg: 12 },
    { id: 318, name:'TIMAUKEL', reg: 12 },
    { id: 313, name:'TORRES DEL PAINE', reg: 12 },
    { id: 109, name:'ALHUE', reg: 13 },
    { id: 99, name:'CALERA DE TANGO', reg: 13 },
    { id: 333, name:'CERRILLOS', reg: 13 },
    { id: 324, name:'CERRO NAVIA', reg: 13 },
    { id: 76, name:'COLINA', reg: 13 },
    { id: 75, name:'CONCHALI', reg: 13 },
    { id: 83, name:'CURACAVI', reg: 13 },
    { id: 338, name:'EL BOSQUE', reg: 13 },
    { id: 89, name:'EL MONTE', reg: 13 },
    { id: 328, name:'ESTACION CENTRAL', reg: 13 },
    { id: 334, name:'HUECHURABA', reg: 13 },
    { id: 330, name:'INDEPENDENCIA', reg: 13 },
    { id: 87, name:'ISLA DE MAIPO', reg: 13 },
    { id: 96, name:'LA CISTERNA', reg: 13 },
    { id: 93, name:'LA FLORIDA', reg: 13 },
    { id: 97, name:'LA GRANJA', reg: 13 },
    { id: 327, name:'LA PINTANA', reg: 13 },
    { id: 92, name:'LA REINA', reg: 13 },
    { id: 78, name:'LAMPA', reg: 13 },
    { id: 71, name:'LAS CONDES', reg: 13 },
    { id: 332, name:'LO BARNECHEA', reg: 13 },
    { id: 337, name:'LO ESPEJO', reg: 13 },
    { id: 325, name:'LO PRADO', reg: 13 },
    { id: 323, name:'MACUL', reg: 13 },
    { id: 94, name:'MAIPU', reg: 13 },
    { id: 90, name:'MARIA PINTO', reg: 13 },
    { id: 88, name:'MELIPILLA', reg: 13 },
    { id: 91, name:'ÑUÑOA', reg: 13 },
    { id: 339, name:'PADRE HURTADO', reg: 13 },
    { id: 104, name:'PAINE', reg: 13 },
    { id: 336, name:'PEDRO AGUIRRE CERDA', reg: 13 },
    { id: 85, name:'PEÑAFLOR', reg: 13 },
    { id: 322, name:'PEÑALOLEN', reg: 13 },
    { id: 101, name:'PIRQUE', reg: 13 },
    { id: 72, name:'PROVIDENCIA', reg: 13 },
    { id: 82, name:'PUDAHUEL', reg: 13 },
    { id: 100, name:'PUENTE ALTO', reg: 13 },
    { id: 79, name:'QUILICURA', reg: 13 },
    { id: 81, name:'QUINTA NORMAL', reg: 13 },
    { id: 329, name:'RECOLETA', reg: 13 },
    { id: 77, name:'RENCA', reg: 13 },
    { id: 98, name:'SAN BERNARDO', reg: 13 },
    { id: 335, name:'SAN JOAQUIN', reg: 13 },
    { id: 95, name:'SAN MIGUEL', reg: 13 },
    { id: 108, name:'SAN PEDRO', reg: 13 },
    { id: 326, name:'SAN RAMON', reg: 13 },
    { id: 70, name:'SANTIAGO CENTRO', reg: 13 },
    { id: 86, name:'TALAGANTE', reg: 13 },
    { id: 80, name:'TIL-TIL', reg: 13 },
    { id: 331, name:'VITACURA', reg: 13 },
    { id: 244, name:'CORRAL', reg: 14 },
    { id: 248, name:'FUTRONO', reg: 14 },
    { id: 251, name:'LA UNION', reg: 14 },
    { id: 254, name:'LAGO RANCO', reg: 14 },
    { id: 249, name:'LANCO', reg: 14 },
    { id: 247, name:'LOS LAGOS', reg: 14 },
    { id: 246, name:'MAFIL', reg: 14 },
    { id: 245, name:'MARIQUINA', reg: 14 },
    { id: 243, name:'VALDIVIA', reg: 14 },
    { id: 250, name:'PANGUIPULLI', reg: 14 },
    { id: 252, name:'PAILLACO', reg: 14 },
    { id: 253, name:'RIO BUENO', reg: 14 },
    { id: 1, name:'ARICA', reg: 15 },
    { id: 295, name:'CAMARONES', reg: 15 },
    { id: 293, name:'GENERAL LAGOS', reg: 15 },
    { id: 294, name:'PUTRE', reg: 15 }
];


export {
    regiones,
    comunas,
}