import React from 'react';
import {Table, Col, Row, Button, Form, Container} from 'react-bootstrap';
import {ArrowClockwise} from 'react-bootstrap-icons'
import usePatientList from 'happ-custom-hooks/usePatientList/usePatientList';
const initialState = {
    dni: ''
}
const PatientList = () => {
    const { 
        state,
        PatientListTable,
        handlerChangeDni,
        searchByDni,
        refreshPatientList
    } = usePatientList(initialState);
    const {dni} = state;
    return ( 
        <Container>
        <Row>
            <Col md={4} sm={4} lg={4} className="col-filter mb-3 mt-5">
                <Form.Control 
                    type="text" 
                    name="dni" 
                    placeholder="Buscar un rut"
                    onChange={handlerChangeDni}
                    value={dni ? dni : ''} 
                />
                <Button
                    type='button'
                    onClick={searchByDni}
                    className='btn btn-primary'
                >Buscar</Button>
            </Col>
            <Col md={8} sm={8} lg={8} className='mb-3 mt-5'>
                <Button
                    type='button'
                    className='btn btn-primary float-end'
                    onClick={refreshPatientList}
                >
                    <ArrowClockwise 
                        className='refresh-icon'
                    />
                    Refrescar lista
                </Button>
            </Col>
            <Col md={12} sm={12} lg={12}>
                <PatientListTable />
            </Col>
        </Row>
        </Container>
     );
}
 
export default PatientList;