import React from 'react';
import AntropometriaForm from 'happ-components/AntropometriaForm/AntropometriaForm';
import InfoPatientNav from 'happ-components/Navbar/InfoPatientNav';

const Antropometria = () => {
    return (
        <>
            <InfoPatientNav />
            <AntropometriaForm />
        </> 
     );
}
 
export default Antropometria;