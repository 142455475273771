import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { 
    saveProstateAntigenAction, 
    getProstateAntigenByPatienDniAction 
} from "happ-redux/happ-actions/exams/examsActions";

const useProstateAntigenForm = (initState) => {
    const [state, setState] = useState(initState);
    const [lastProstateAntigen, setLastProstateAntigen] = useState(null);
    const [submit, setSubmit] = useState(false);
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const { patient } = useSelector(state => state.profile);
    const { prostateAntigen, errorProstateAntigen, isProstateAntigenStatusSave, loading } = useSelector(state => state.exams);

    const handlerChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
            patient: patient?._id,
        });
    }

    useEffect(() => {
      dispatch(getProstateAntigenByPatienDniAction(patient?.dni));
    }, []);

    useEffect(() => {
        if (prostateAntigen || errorProstateAntigen) {
            setLastProstateAntigen(prostateAntigen);
        }
    }, [prostateAntigen, errorProstateAntigen])
    

    useEffect(() => {
      if (submit) {
        if (Object.keys(errors).length) {
            return;     
        } else {
            sendResults();
        }
        setSubmit(false);
      }
    }, [submit]);

    const validateForm = () => {
        const errors = {};
        const { isProstateAntigen, result, patient:patientState } = state;
        if (!isProstateAntigen) {
            errors.isProstateAntigen = 'Es Requerido *';
        }
        if (!result) {
            errors.result = 'Es Requerido *';
        }
        if (result !== null && isProstateAntigen !== null && patientState === null) {
            errors.patient = 'No se puede guardar el resultado, recargue la página y vuelva a ingresar el RUT para guardar el examen';
        } 
        return errors;
    }

    const cleanForm = () => {
        state.isProstateAntigen = null;
        state.result = null;
        state.patient = null;
    }

    const verifySubmit = e => {
        e.preventDefault();
        const errors = validateForm();
        setErrors(errors);
        setSubmit(true);
    }

    function sendResults() {
        state.isProstateAntigen === 'no' ? state.isProstateAntigen = false : state.isProstateAntigen = true;
        dispatch(saveProstateAntigenAction(state));
        cleanForm();
    }

    return {
        state,
        errors,
        loading,
        lastProstateAntigen,
        isProstateAntigenStatusSave,
        handlerChange,
        verifySubmit,
    };
}
 
export default useProstateAntigenForm;