import axiosClient from 'happ-config/axios';

const saveSurvey = async (token, survey, patient, type, name) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const body = {
            type,
            patient,
            results: survey.survey,
            name
        }
        const response = await axiosClient.post('/survey', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    saveSurvey,
}