import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './login.css';
import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import {Link} from 'react-router-dom';
import logo from 'assets/img/logo-happ-heart.svg';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { adminAuthAction } from 'happ-redux/happ-actions/auth/authActions';

const loginState = {
    dni: '',
    password: ''
}
const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        state,
        errors,
        handlerChange,
        handlerChangeDni,
        handlerSubmit
    } = useValidatorForm(loginState, login, 'login');
    const { errorLogin, errorsMsg } = useSelector(state => state.auth);
    const {dni, password} = state;
    function login() {
        const authParams = {
            rut: dni,
            password,
            origin: 'tester'
        }
        dispatch(adminAuthAction(authParams, navigate));
    }
    return ( 
        <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
            <Col md={8} lg={6} xs={12}>
                <Card className="shadow">
                    <Card.Body>
                        <div className="mb-3 mt-md-4">
                            <div className="content-logo">
                                <img src={logo} alt="description"/>
                            </div>
                            <div className="mb-3">
                                <Form
                                    onSubmit={handlerSubmit}
                                >
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label className="text-center">
                                        Rut
                                    </Form.Label>
                                    <Form.Control 
                                        name="dni"
                                        type="dni" 
                                        placeholder="" 
                                        value={dni ? dni : ''}
                                        onChange={handlerChangeDni}
                                    />
                                    {errors && errors.dni ? (
                                        <p className='p-error-login'>{errors.dni}</p>
                                    ) : null}
                                    {errorLogin && errorsMsg.dni ? (
                                        <p className='p-error-login'>{errorsMsg.dni}</p>
                                    ) : null}
                                </Form.Group>

                                <Form.Group
                                    className="mb-3"
                                    controlId="formBasicPassword"
                                >
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control 
                                        name="password" 
                                        type="password" 
                                        placeholder="******" 
                                        value={password ? password : ''}
                                        onChange={handlerChange}
                                    />
                                    {errors && errors.password ? (
                                        <p className='p-error-login'>{errors.password}</p>
                                    ) : null}
                                    {errorLogin && errorsMsg.password ? (
                                        <p className='p-error-login'>{errorsMsg.password}</p>
                                    ) : null}
                                    {errorLogin && errorsMsg.rol ? (
                                        <p className='p-error-login'>{errorsMsg.rol}</p>
                                    ) : null}
                                    {errorLogin && errorsMsg.server ? (
                                        <p className='p-error-login'>{errorsMsg.server}</p>
                                    ) : null}
                                </Form.Group>
                                <Form.Group
                                    className="mb-3"
                                    controlId="formBasicCheckbox"
                                >
                                <div className="text-center">
                                    <Link to="/forgot-your-password">¿ Olvido su contraseña ?</Link>
                                </div>    
                                </Form.Group>
                                <div className="d-grid">
                                    <Button 
                                        variant="primary" 
                                        type="submit"
                                    >
                                        Continuar
                                    </Button>
                                </div>
                                </Form>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
     );
}
 
export default Login;