import {
    INIT_GET_BOXES,
    GET_BOXES,
    ERROR_GET_BOXES,
    INIT_ASSIGN_BOX_TO_PATIENT,
    ASSIGN_BOX_TO_PATIENT,
    ERROR_ASSIGN_BOX_TO_PATIENT,
    CLEAR_STATUS_BOX
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    boxes: null,
    errorBoxList: false,
    assignBox: null,
    assignBoxMsg: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type) {
        case INIT_GET_BOXES:
        case INIT_ASSIGN_BOX_TO_PATIENT:    
            return {
                ...state,
                loading: true
            }
        case GET_BOXES:
            return {
                ...state,
                loading: false,
                boxes: action.payload,
                errorBoxList: false
            }
        case ERROR_GET_BOXES:
            return {
                ...state,
                loading: false,
                boxes: null,
                errorBoxList: true
            }
        case ASSIGN_BOX_TO_PATIENT:
            return {
                ...state,
                assignBox: true,
                assignBoxMsg: null,
            }            
        case ERROR_ASSIGN_BOX_TO_PATIENT:
            return {
                ...state,
                assignBox: false,
                assignBoxMsg: action.payload
            }
        case CLEAR_STATUS_BOX: 
            return {
                ...state,
                assignBox: null,
                assignBoxMsg: null,
            }        
        default:
            return state;
    }
}