import {
    INIT_VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED_ERROR,
    INIT_CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED_ERROR
} from 'happ-redux/happ-types';

import { verificateDniImed, certificateDniImed } from 'happ-redux/happ-services/validate-identity/validate-identity';

export function sendToVerificateIdentityAction(dni){
    return async (dispatch) => {
        dispatch(init_verificate_dni_imet());
        try {
            const response = await verificateDniImed(dni);
            if (response.status === 200) {
                dispatch(verificate_dni_imet(response.data));
            } else {
                dispatch(verificate_dni_imet_error(response.data.msg));
            }
        } catch (error) {
            dispatch(verificate_dni_imet_error(error.data.msg));
        }
    }
}

export function certificationDniImedAction(body) {
    return async (dispatch) => {
        dispatch(init_certification_dni_imed());
        try {
            const response = await certificateDniImed(body);
            if ( response.status === 200 ) {
                dispatch(certificate_dni_imed(response.data));
            } else {
                dispatch(certificate_dni_imed_error(response.data.msg));
            }
        } catch (error) {
            dispatch(certificate_dni_imed_error(error.data.msg));
        }
    }
}
const init_verificate_dni_imet = () => ({
    type: INIT_VERIFICATE_DNI_IMED
});

const verificate_dni_imet = response => ({
    type: VERIFICATE_DNI_IMED,
    payload: response
});

const verificate_dni_imet_error = error => ({
    type: VERIFICATE_DNI_IMED_ERROR,
    payload: error
});

const init_certification_dni_imed = () => ({
    type: INIT_CERTIFICATION_DNI_IMED
});

const certificate_dni_imed = response => ({
    type: CERTIFICATION_DNI_IMED,
    payload: response
});

const certificate_dni_imed_error = error => ({
    type: CERTIFICATION_DNI_IMED_ERROR,
    payload: error
});