import {
    INIT_GET_PATIENT_LIST,
    GET_PATIENT_LIST,
    ERROR_GET_PATIENT_LIST
} from 'happ-redux/happ-types';

const initialState = {
    patients: null,
    errorPatients: false,
    loading: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_GET_PATIENT_LIST:
            return {
                ...state,
                loading: true,
            }
        case GET_PATIENT_LIST:
            return {
                ...state,
                loading: false,
                patients: action.payload.patients,
                errorPatients: false
            }    
        case ERROR_GET_PATIENT_LIST:
            return {
                ...state,
                loading: false,
                patients: null,
                errorPatients: true,
            }    
        default:
            return state;
    }
}