import {
    INIT_GET_PATIENT_LIST,
    GET_PATIENT_LIST,
    ERROR_GET_PATIENT_LIST
} from 'happ-redux/happ-types';
import { getPatientList } from 'happ-redux/happ-services/patient-list/patient-list';

export function getPatientListAction(operatives) {
    return async dispatch => {
        dispatch(initGetPatientList());
        try {
            const token = localStorage.getItem('token');
            const response = await getPatientList(token, operatives);
            // console.log(response);
            if (response.data.status === 200) {
                dispatch(getList(response.data));
            } else {
                dispatch(errorGetList(response.data.msg));
            }
        } catch (error) {
            dispatch(errorGetList(error.data.msg));
        }
    }
}

const initGetPatientList = () => ({
    type: INIT_GET_PATIENT_LIST,
});

const getList = response => ({
    type: GET_PATIENT_LIST,
    payload: response
});

const errorGetList = error => ({
    type: ERROR_GET_PATIENT_LIST,
    payload: error,
});