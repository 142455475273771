import {
    INIT_GET_BOXES,
    GET_BOXES,
    ERROR_GET_BOXES,
    INIT_ASSIGN_BOX_TO_PATIENT,
    ASSIGN_BOX_TO_PATIENT,
    ERROR_ASSIGN_BOX_TO_PATIENT,
    CLEAR_STATUS_BOX
} from 'happ-redux/happ-types';
import { getBoxList, assignBoxToPatient } from 'happ-redux/happ-services/box/box-services';

export function getBoxListAction(admin, medicalStage, navigate) {
    return async dispatch => {
        dispatch(initGetBox());
        try {
            const token = localStorage.getItem('token');
            const response = await getBoxList(token, admin, medicalStage);
            if (response.status === 200) {
                dispatch(getBox(response.data));
            } else {
                dispatch(errorGetBox(response.data.message));
            }
        } catch (error) {
            dispatch(errorGetBox('Hubo un error'));
        }
    }
}

export function assignBoxToPatientAction(body) {
    return async dispatch => {
        dispatch(initAssignBoxPatient());
        try {
            const token = localStorage.getItem('token');
            const response = await assignBoxToPatient(token, body);
            console.log(response);
            if (response.data.status === 201) {
                dispatch(assignBoxPatient());
                setTimeout(() => {
                    dispatch(cleanStatus());
                }, 2000);
            } else {
                dispatch(errorAssignBoxPatient(response.data.msg));
                setTimeout(() => {
                    dispatch(cleanStatus());
                }, 2000);
            }
        } catch (error) {
            dispatch(errorAssignBoxPatient('Hubo un error en el servidor'));
            setTimeout(() => {
                dispatch(cleanStatus());
            }, 2000);
        }
    }
}

const initGetBox = () => ({
    type: INIT_GET_BOXES
});

const getBox = response => ({
    type: GET_BOXES,
    payload: response
});

const errorGetBox = error => ({
    type: ERROR_GET_BOXES,
    payload: error,
});

const initAssignBoxPatient = () => ({
    type: INIT_ASSIGN_BOX_TO_PATIENT,
});

const assignBoxPatient = () => ({
    type: ASSIGN_BOX_TO_PATIENT,
});

const errorAssignBoxPatient = error => ({
    type: ERROR_ASSIGN_BOX_TO_PATIENT,
    payload: error,
});

const cleanStatus = () => ({
    type: CLEAR_STATUS_BOX,
});


