import { Button, Row, Col } from "react-bootstrap";

const ConsentSignatureType = ({handlerSelectConsentType}) => {
    return ( 
        <Row>
            <Col sm={12} className='d-flex justify-content-center mb-5'>
                <h3>¿Qué tipo de consentimiento firmará?</h3>
            </Col>
            <Col sm={12} className='d-flex justify-content-center btn-content-flex'>
                <Button
                    className='btn-c-d'
                    onClick={() => handlerSelectConsentType('digital')}
                >
                    Consentimiento digital
                </Button>
                <Button
                    className='btn-c-p'
                    onClick={() => handlerSelectConsentType('paper')}
                >
                    Consentimiento en papel
                </Button>
            </Col>
        </Row>
    );
}
 
export default ConsentSignatureType;