import { Button, Form } from 'react-bootstrap';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import ErrorMessage from 'happ-components/Alerts/ErrorMessage';

const ValidateConsentSignatureCode = ({
    phoneCode,
    phone,
    email,
    errors,
    showAlert,
    resendCodeProcess,
    consentCode,
    handlerChangeNumber,
    resendConsentSignatureCodeSMS,
    validateConsentSignatureCode,
    changePhone,
}) => {
    return ( 
        <>
            <div className='d-flex justify-content-center'>
                <div className='mt-5'>
                    <p className='pending-status-consent-signature'>
                        Estado: 
                        <strong>Consentimiento pendiente</strong>
                        <ExclamationTriangleFill 
                            className='pending-icon-check-signature'
                        />
                    </p>
                </div>
            </div>
            <div className='justify-content-center content-info-signature'>
                <div className='mt-5 bullets-signature'>
                    <p>Se enviará un SMS al número <strong>{`+${phoneCode}${phone}`}</strong> con un código para aceptar el consentimiento.</p>
                    <p>Se enviará un correo con el consentimiento ya firmado a <strong>{email}</strong></p>
                </div>
                <div className='mt-5 bullets-signature'>
                    <Button
                        variant="info"
                        style={{ color: '#fff' }}
                        onClick={resendConsentSignatureCodeSMS}
                    >
                        ¿No ha llegado el código? Solicita otro
                    </Button>
                </div>
                <div className='mt-5 bullets-signature'>
                    <Button
                        variant="light"
                        style={{ color: '#0050FF' }}
                        onClick={changePhone}
                    >
                        Cambiar datos del pacientes
                    </Button>
                </div>
            </div>
            <br></br>
            <div className='justify-content-center d-flex-btn mt-4 mb-5 content-info-signature'>
                {errors ? (
                    <>
                        {Object.keys(errors).map((error, pos) => (
                            <>
                                <ErrorMessage
                                    key={pos}
                                    message={errors[error]}
                                />
                            </>
                        ))}
                    </>
                ): null}
            </div>
            <div className='d-flex justify-content-center d-flex-code-content mt-5'>
                <Form.Control
                    name='consentCode' 
                    type="text" 
                    placeholder="1234" 
                    maxLength="4"
                    onChange={handlerChangeNumber}
                    className={errors && errors.consentCode ? 'input-code-signature border-require' : 'input-code-signature'}
                    value={consentCode !== '' ? consentCode : ''}
                />
                <Button
                    className='btn btn-primary btn-signature'
                    onClick={validateConsentSignatureCode}
                >Validar código</Button>
            </div>
        </>
    );
}
 
export default ValidateConsentSignatureCode;