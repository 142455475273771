
const ExamListParams = ({params}) => {
    return (
        <>
            {Object.keys(params).map(item => (
                <>
                    <small className="">
                        {item}: 
                        <strong>
                            {
                                typeof params[item] === "boolean" ? 
                                params[item] === true ? 'Si' : 'No' : 
                                params[item]}
                        </strong>
                    </small>,
                </>
            ))}
        </>
    );
}
 
export default ExamListParams;