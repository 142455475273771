import axiosClient from 'happ-config/axios';
import { regiones, comunas } from 'happ-data/data';
import dayjs from 'dayjs';

const getPatientByDni = async (token, dni) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/patients/find-patient-by-dni/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}


const getAllCompanies = async (token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/companies`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getAllBranches = async (token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/branch-office/all-branches`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const createPatient = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post(`/patients`, body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const updatePatientProfile = async (_id, patient, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const body = {
            firstName: patient.firstName,
            lastName: patient.lastName,
            fullName: `${patient.firstName} ${patient.lastName}`,
            birthDay: dayjs(patient.birthDay).format('YYYY-MM-DDT00:00:00.000Z'),
            email: patient.email,
            gender: patient.gender,
            phone: patient.phone,
            healthForecast: patient.healthForecast,
            address: [
                {
                    commune: patient.commune,
                    region: patient.region,
                    street: patient.street,
                    alias: 'Dirección'
                }
            ],
        }
        const response = await axiosClient.patch(`/patients/${_id}`, body, headers)
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const communesByReg = region => {
    const id = getIdRegBayName(region);
    if ( id !== null ) {
        return comunas.filter(comuna => comuna.reg === id);
    } 
    return null;   
}

const getIdRegBayName = region => {
    if ( region ) {
        let id = 0;
        regiones.some(reg => {
            if (reg.name === region) {
                id = reg.id;
            }
        })
        return id;
    }
    return null;
}

export {
    getPatientByDni,
    communesByReg,
    updatePatientProfile,
    getAllCompanies,
    createPatient,
    getAllBranches,
    regiones
}