import {
    INIT_LOGIN,
    LOGIN,
    ERROR_LOGIN,
    LOGOUT,
    INIT_VALIDATE_SESSION,
    VALIDATE_SESSION,
    ERROR_VALIDATE_SESSION,
    INIT_SUMMARY_PATIENT,
    SUMMARY_PATIENT,
    ERROR_SUMMARY_PATIENT
} from 'happ-redux/happ-types';

const initialState = {
    loginProfile: null,
    login: false,
    session: false,
    errorLogin: false,
    operatives: null,
    errorsMsg: {},
    loading: false,
    summarySurvey: null,
    summaryResults: null,
    examsLabs: null,
    errorSummary: false,
    continueStatus: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_VALIDATE_SESSION:
        case INIT_SUMMARY_PATIENT:    
            return {
                ...state,
                loading: true
            }
        case INIT_LOGIN:
            localStorage.removeItem('token');
            return {
                ...state,
                loading: true,
                session: true
            }
        case LOGIN:
            localStorage.setItem('token', action.payload.token);  
            return {
                ...state,
                loading: false,
                session: true,
                errorsMsg: {},
                errorLogin: false,
                login: true,
                operatives: action.payload.operatives,
                loginProfile: action.payload.admin,
            }
        case ERROR_LOGIN:
            localStorage.clear();
            return {
                ...state,
                loginProfile: null,
                login: false,
                operatives: null,
                session: false,
                errorLogin: true,
                errorsMsg: action.payload,
                loading: false
            }
        case VALIDATE_SESSION: 
            return {
                ...state,
                login: true,
                loading: false,
                operatives: action.payload.operatives,
                loginProfile: action.payload.profile,
            }
        case ERROR_VALIDATE_SESSION:        
        case LOGOUT:
            localStorage.clear();
            return {
                loginProfile: null,
                login: false,
                session: false,
                errorLogin: false,
                operatives: null,
                errorsMsg: {},
                loading: false
            }
        case SUMMARY_PATIENT: 
            return {
                ...state,
                summarySurvey: action.payload.survey,
                summaryResults: action.payload.summaryResults,
                examsLabs: action.payload.examsLabs,
                errorSummary: false,
                continueStatus: action.payload.continueStatus,
            }    
        case ERROR_SUMMARY_PATIENT:
            return {
                ...state,
                summarySurvey: null,
                summaryResults: null,
                examsLabs: null,
                continueStatus: null,
                errorSummary: true,
            }    
        default:
            return state;
    }
}