import { useState } from "react";
import { Form, Col, Row, Button } from "react-bootstrap";
import useProstateAntigenForm from "happ-custom-hooks/useProstateAntigenForm/useProstateAntigenForm";
import SweetAlert2 from 'react-sweetalert2';

import moment from "moment";

const initState = {
    patient: null,
    isProstateAntigen: null,
    result: null,
}
const ProstateAntigen = () => {
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    const [errorExamMessage, setErrorExamMessage] = useState({
        show: true,
        text: 'No se pudo registrar el resultado, intente de nuevo en unos minutos',
        type: 'error',
        icon: 'error',
    });
    const {
        state,
        errors,
        loading,
        lastProstateAntigen,
        isProstateAntigenStatusSave,
        handlerChange,
        verifySubmit,
    } = useProstateAntigenForm(initState);
    const { isProstateAntigen, result } = state;
    return (
        <>
            {loading === false && isProstateAntigenStatusSave === true ? (
                <SweetAlert2
                    {...examMessage}
                />
            ) : null} 
            {loading === false && isProstateAntigenStatusSave === false ? (
                <SweetAlert2
                    {...errorExamMessage}
                />
            ) : null} 
            <Row className='justify-content-md-center'>
                <h3 className="text-center">Antígeno Prostático</h3>
                <Col md={9} sm={12} lg={9} className="col-question">
                        <h6>1.- ¿Se realizo antígeno prostático?</h6>
                        <Form.Check
                            className='select-question'
                            label="Si"
                            checked={String(isProstateAntigen) === "yes"}
                            onChange={handlerChange}
                            value={'yes'}
                            name="isProstateAntigen"
                            type="radio"
                        />
                        <Form.Check
                            className='select-question'
                            label="No"
                            checked={String(isProstateAntigen) === "no"}
                            onChange={handlerChange}
                            value={'no'}
                            name="isProstateAntigen"
                            type="radio"
                        />
                        <p className='p-errors-form'>{errors.isProstateAntigen && errors.isProstateAntigen}</p>
                </Col>
                <Col md={9} sm={12} lg={9} className="col-question">
                        <h6>1.- ¿Resultado?</h6>
                        <Form.Check
                            className='select-question'
                            label="Positivo"
                            checked={String(result) === "positive"}
                            onChange={handlerChange}
                            value={'positive'}
                            name="result"
                            type="radio"
                        />
                        <Form.Check
                            className='select-question'
                            label="Negativo"
                            checked={String(result) === "negative"}
                            onChange={handlerChange}
                            value={'negative'}
                            name="result"
                            type="radio"
                        />
                        <p className='p-errors-form'>{errors.result && errors.result}</p>
                </Col>
                <Col className='col-btns mt-3 text-center' md={5} sm={12} lg={5}>
                    <p className='p-errors-form'>{errors.patient && errors.patient}</p>
                    <Button 
                        className='shadow btn-grey-survey'
                        onClick={verifySubmit}
                    >
                        Guardar
                    </Button>
                </Col>
            </Row>
            <Row className='justify-content-md-center mt-5'>
                <Col md={9} sm={12} lg={9} className="">
                    {lastProstateAntigen ? (
                        <>
                            <p>¿Se realizo antígeno prostático?: <strong>{lastProstateAntigen.isProstateAntigen ? 'Si': 'No'}</strong></p>
                            <p>¿Resultado?: <strong>{lastProstateAntigen.result === 'positive' ? 'Positivo' : 'Negativo'}</strong></p>
                            <p>Fecha: <strong>{moment.utc(lastProstateAntigen.createdAt).format('DD-MM-YYYY')}</strong></p>
                        </>
                    ) : null}
                </Col>
            </Row>
        </>
    );
}
 
export default ProstateAntigen;