import {
    INI_GET_STATUS_CONSENT_SIGNATURE,
    GET_STATUS_CONSENT_SIGNATURE,
    ERROR_GET_STATUS_CONSENT_SIGNATURE,
    INI_GET_PDF_SIGNED_CONSENT_SIGNATURE,
    GET_PDF_SIGNED_CONSENT_SIGNATURE,
    ERROR_GET_PDF_SIGNED_CONSENT_SIGNATURE,
    INIT_GET_PDF_PREVIEW_CONSENT_SIGNATURE,
    GET_PDF_PREVIEW_CONSENT_SIGNATURE,
    ERROR_GET_PDF_PREVIEW_CONSENT_SIGNATURE,
    INIT_ACCEPT_TERMS,
    ACCEPT_TERMS,
    ERROR_ACCEPT_TERMS,
    INIT_CONFIRM_TERMS,
    CONFIRM_TERMS,
    ERROR_CONFIRM_TERMS,
    INIT_CONFIRM_PAPER_TERMS,
    CONFIRM_PAPER_TERMS,
    ERROR_CONFIRM_PAPER_TERMS,
    CLEAN_CONSET_SIGNATURE,
} from 'happ-redux/happ-types';

import { 
    getPatientData, 
    acceptTerms,
    getPreviewPdf,
    getSignedPdf, 
    confirmTerms,
    confirmPaperTerms,
} from 'happ-redux/happ-services/consent-signature/consent-signature-services';

export function getConsentSignatureStatusAction(patient){
    return async dispatch => {
        dispatch(initGetStatusConsentSignature())
        try {
            const adminToken = localStorage.getItem('token');
            const response = await getPatientData(adminToken, patient);
            if (response.status === 200) {
                dispatch(getStatusConsentSignature(response.data));
            } else {
                dispatch(errorGetStatusConsentSignature());
            }
        } catch (error) {
            dispatch(errorGetStatusConsentSignature());
        }
    }
};

const initGetStatusConsentSignature = () => ({
    type: INI_GET_STATUS_CONSENT_SIGNATURE,
});
const getStatusConsentSignature = (response) => ({
    type: GET_STATUS_CONSENT_SIGNATURE,
    payload: response,
});
const errorGetStatusConsentSignature = () => ({
    type: ERROR_GET_STATUS_CONSENT_SIGNATURE,
});

export function sendCodeConsentSignatureAction(patient, body){
    return async dispatch => {
        dispatch(initSendCodeConsentSignature());
        try {
            const adminToken = localStorage.getItem('token');
            const response = await acceptTerms(adminToken, patient, body);
            if (response.status === 200) {
                dispatch(sendCodeConsentSignature(response.data.data));
            } else {
                dispatch(errorSendCodeConsentSignature());
            }
        } catch (error) {
            dispatch(errorSendCodeConsentSignature());
        }
    }
};

const initSendCodeConsentSignature = () => ({
    type: INIT_ACCEPT_TERMS,
});
const sendCodeConsentSignature = (response) => ({
    type: ACCEPT_TERMS,
    payload: response,
});
const errorSendCodeConsentSignature = () => ({
    type: ERROR_ACCEPT_TERMS,
});

export function getPreviewPdfConsentSignatureAction(patient) {
    return async dispatch => {
        dispatch(initGetPdfPreviewConsentSignature());
        try {
            const adminToken = localStorage.getItem('token');
            const response = await getPreviewPdf(adminToken, patient);
            if (response.status === 200) {
                dispatch(getPdfPreviewConsentSignature(response.data));
            } else {
                dispatch(errorGetPdfPreviewConsentSignature());
            }
        } catch (error) {
            dispatch(errorGetPdfPreviewConsentSignature());
        }
    }
}

const initGetPdfPreviewConsentSignature = () => ({
    type: INIT_GET_PDF_PREVIEW_CONSENT_SIGNATURE,
});
const getPdfPreviewConsentSignature = (response) => ({
    type: GET_PDF_PREVIEW_CONSENT_SIGNATURE,
    payload: response,
});
const errorGetPdfPreviewConsentSignature = () => ({
    type: ERROR_GET_PDF_PREVIEW_CONSENT_SIGNATURE,
});

export function getSignedPdfConsentSignatureAction(patient) {
    return async dispatch => {
        dispatch(initSignedPdf());
        try {
            const adminToken = localStorage.getItem('token');
            const response = await getSignedPdf(adminToken, patient);
            if (response.status === 200) {
                dispatch(signedPdf(response.data.data.url));
            } else {
                dispatch(errorSignedPdf());
            }
        } catch (error) {
            dispatch(errorSignedPdf());
        }
    }
}

const initSignedPdf = () => ({
    type: INI_GET_PDF_SIGNED_CONSENT_SIGNATURE,
});
const signedPdf = (response) => ({
    type: GET_PDF_SIGNED_CONSENT_SIGNATURE,
    payload: response,
});
const errorSignedPdf = () => ({
    type: ERROR_GET_PDF_SIGNED_CONSENT_SIGNATURE,
});

export function confirmPaperTermsAction(patient){
    return async dispatch => {
        dispatch(initConfirmPaperTermsConsentSignature());
        try {
            const adminToken = localStorage.getItem('token');
            const response = await confirmPaperTerms(adminToken, patient);
            if (response.status === 201) {
                dispatch(confirmPaperTermsConsentSignature());
            } else {
                dispatch(errorConfirmPaperTermsConsentSignature());
            }
        } catch (error) {
            dispatch(errorConfirmPaperTermsConsentSignature());
        }
    }
};

const initConfirmPaperTermsConsentSignature = () => ({
    type: INIT_CONFIRM_PAPER_TERMS,
});
const confirmPaperTermsConsentSignature = () => ({
    type: CONFIRM_PAPER_TERMS,
});
const errorConfirmPaperTermsConsentSignature = () => ({
    type: ERROR_CONFIRM_PAPER_TERMS,
});

export function confirmTermsAction(patient, body){
    return async dispatch => {
        dispatch(initConfirmTermsConsentSignature());
        try {
            const adminToken = localStorage.getItem('token');
            const response = await confirmTerms(adminToken, patient, body);
            if (response.status === 200) {
                dispatch(confirmTermsConsentSignature());
            } else {
                dispatch(errorConfirmTermsConsentSignature());
            }
        } catch (error) {
            dispatch(errorConfirmTermsConsentSignature());
        }
    }
};

const initConfirmTermsConsentSignature = () => ({
    type: INIT_CONFIRM_TERMS,
});
const confirmTermsConsentSignature = () => ({
    type: CONFIRM_TERMS,
});
const errorConfirmTermsConsentSignature = () => ({
    type: ERROR_CONFIRM_TERMS,
});

export function cleanConsetSignatureAction() {
    return dispatch => {
        dispatch(cleanConsentSignatureReducer())
    }
}

const cleanConsentSignatureReducer = () => ({
    type: CLEAN_CONSET_SIGNATURE,
});