import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {Row, Col, Form, Button} from 'react-bootstrap';
import { checkRut, formatRut } from "react-rut-formatter";
import { sendToVerificateIdentityAction, certificationDniImedAction } from 'happ-redux/happ-actions/validateIdentity/validateIdentityAction';

const Enrollment = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const {urlVerificateIdentity, certificateOk, certificateErrorMsg} = useSelector(state => state.identity);
    const [enrollment, setEnrollment] = useState({
        dni: '',
    });
    const [errorDni, setErrorDni] = useState(false);
    const {dni} = enrollment;

    useEffect(() => {
        if ( location.pathname === '/validar-identidad' && location.search !== '' ) {
            const { search } = location;
            const subString = search.split('id_certificacion=', 2)[1];
            const separateItems = subString.split('&');
            const idCertification = separateItems[0];
            const status = separateItems[1].split('=')[1];
            const msgError = separateItems[3].split('=')[1];
            const certificateBody = {
                idCertification,
                status,
                msgError
            }
            dispatch(certificationDniImedAction(certificateBody));
        }
    }, [location])

    const handlerChangeDni = e => {
        const dni = e.target.value;
        const formatted = formatRut(dni);
        setEnrollment({
            ...enrollment,
            dni: formatted
        });
    }
    const sendToVerificateIdentity = e => {
        e.preventDefault();
        if (dni === '' || (!checkRut(dni))) {
            setErrorDni(true);
            return;
        }
        setErrorDni(false);
        dispatch(sendToVerificateIdentityAction(dni));
    }
    const closeCurrentWindow = e => {
        window.opener = window.self;
        window.close();
    } 
    const openImed = () => {
        const url = urlVerificateIdentity ? urlVerificateIdentity : '#'
        window.open(url, 'newwindow', 'width=700,height=700'); 
        return false;
    }
    return ( 
        <>
        <Row className="align-items-center justify-content-center mt-5">
            <Col sm={3} className="my-1">
                <Form.Label 
                    htmlFor="dni" 
                >
                    Rut paciente
                </Form.Label>
                <Form.Control 
                    id="dni"
                    name="dni"
                    className="inputDni" 
                    value={dni ? dni : ''}
                    onChange={handlerChangeDni}
                    placeholder="11111111-1" 
                />
            </Col>
            <Col xs="auto" className="">
                <Button
                    className="btn-dni" 
                    onClick={sendToVerificateIdentity}
                    type="button"
                    >Buscar</Button>
            </Col>
            {errorDni ? (
                <p className="p_require text-center">El rut no es valido</p>
            ) : null}
        </Row>
        {urlVerificateIdentity ? (
            <Row className="align-items-center justify-content-center">
                <Col sm={3} className="my-1 text-center mt-5">
                    <Button 
                        className='btn btn-primary'
                        type='button'
                        onClick={openImed}
                    >Validar Identidad</Button>
                </Col>
            </Row>
        ) : null}
        {certificateOk !== null ? (
            <Row className="align-items-center justify-content-center">
                <Col sm={3} className="my-1">
                    {certificateOk}
                </Col>
                <Button
                    type='button'
                    onClick={closeCurrentWindow}
                >Cerrar Ventana</Button>
            </Row>
        ) : null}
        {certificateErrorMsg !== null ? (
            <Row className="align-items-center justify-content-center">
                <Col sm={3} className="my-1">
                    {certificateErrorMsg}
                </Col>
                <Button
                    type='button'
                    onClick={closeCurrentWindow}
                >Cerrar Ventana</Button>
            </Row>
        ) : null}
        </>
    );
}
 
export default Enrollment;