import Alert from 'react-bootstrap/Alert';

const ErrorMessage = ({message}) => {
    return ( 
        <Alert variant="danger" show={true}>
            <>
            <p><strong>{message}</strong></p>
            </>
        </Alert>
    );
}
 
export default ErrorMessage;