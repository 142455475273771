import React from 'react';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const InfoPatientNav = () => {
    const { currentMedicalStage, patient } = useSelector(state => state.profile)
    return ( 
        <Row className="justify-content-md-center mb-3">
            <Col md={4} className="text-center">
                <p>Nombre: <strong>{patient?.fullName}</strong></p>
            </Col>
            <Col md={4} className="text-center">
                <p>Etapa médica: <strong>{currentMedicalStage?.name}</strong></p>
            </Col>
        </Row>
     );
}
 
export default InfoPatientNav;