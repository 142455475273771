import React from 'react';
import SurveyForm from 'happ-components/SurveyForm/SurveyForm';

import InfoPatientNav from 'happ-components/Navbar/InfoPatientNav';
const Survey = () => {
    return (
        <>
        <InfoPatientNav />
        <SurveyForm />
        </> 
     );
}
 
export default Survey;