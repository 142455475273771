import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Col, Row, Form, Button} from 'react-bootstrap';
import SweetAlert2 from 'react-sweetalert2';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { saveExamParamsAction } from 'happ-redux/happ-actions/exams/examsActions';
import { getBloodPressureInfoPatientAction } from 'happ-redux/happ-actions/patient-profile/patientProfileActions';

const initState = {
    sistolica: '',
    diastolica: ''
}
const BloodPressureFormTwo = ({stage}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerChange,
        handlerSubmit
    } = useValidatorForm(initState, sendResults, 'bloodPressure');
    const {sistolica, diastolica} = state;
    const { patient, currentMedicalStage, operativeId, bloodPressureResults, idHypertension } = useSelector(state => state.profile);
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    const { healthBenefit, errorHealthBenefit, saveParams } = useSelector(state => state.exams);
    useEffect(() => {
        if (saveParams !== null && saveParams) {
            dispatch(getBloodPressureInfoPatientAction(patient.dni));
        }
    }, [saveParams])
    
    const {loginProfile} = useSelector(state => state.auth);
    function sendResults() {
        let body = {
            patient: patient._id,
            healthBenefits: idHypertension,
            status: true,
            admin: loginProfile._id,
            operative: operativeId,
            medicalStage: currentMedicalStage._id,
            params: {
                sistolica,
                diastolica
            }
        }
        if (stage === 'blood-pressure-two') {
            body.origin = 'Happ2';
            dispatch(saveExamParamsAction(navigate, body, 'c-1'));        
        }
        state.sistolica = '';
        state.diastolica = '';
    }
    return ( 
        <>
        {saveParams !== null && saveParams ? (
            <SweetAlert2
                {...examMessage}
            />
        ) : null} 
        {saveParams !== null && !saveParams ? (
            <Row className="align-items-center mt-5">
                <p className='success-save'>No se pudo registrar el resultado</p>
            </Row>
        ) : null} 
        <Row className="justify-content-md-center">
            <Col md={7} sm={7} lg={7} className='mt-3'>
                {bloodPressureResults && bloodPressureResults['bloodPressure'] ? (
                    <p>
                        <strong>
                            Primera toma de presión: {bloodPressureResults.bloodPressure.sistolica}/{bloodPressureResults.bloodPressure.diastolica} mmhg
                        </strong>
                    </p> 
                ) : null}
                {bloodPressureResults && bloodPressureResults['bloodPressureTwo'] ? (
                    <p>
                        <strong>
                            Segunda toma de presión: {bloodPressureResults.bloodPressureTwo.sistolica}/{bloodPressureResults.bloodPressureTwo.diastolica} mmhg
                        </strong>
                    </p>
                ) : null}
                {bloodPressureResults && bloodPressureResults['average'] ? (
                    <p>
                        <strong>
                            Promedio: {bloodPressureResults.average.sistolica}/{bloodPressureResults.average.diastolica} mmhg
                        </strong>
                    </p>
                ) : null}
            </Col>
            <Col md={7} sm={7} lg={7} className="content-form">
                <Form
                    onSubmit={handlerSubmit}
                >
                    <Row className='justify-content-md-center'>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Label>Sistolica</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="sistolica" 
                                placeholder="Sistolica"
                                onChange={handlerChange}
                                value={sistolica ? sistolica : ''} 
                            />
                            {errors && errors.sistolica ? (
                                <p>{errors.sistolica}</p>
                            ) : null}
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                            <Form.Label>Diastolica</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="diastolica" 
                                placeholder="Diastolica"
                                onChange={handlerChange}
                                value={diastolica ? diastolica : ''} 
                            />
                            {errors && errors.diastolica ? (
                                <p>{errors.diastolica}</p>
                            ) : null}
                        </Col>
                    </Row>
                    <Col md={12} sm={12} lg={12} className="mt-3 text-center">
                        <Button
                            type='submit'
                            className='btn btn-primary'
                        >Guardar</Button>
                    </Col>
                </Form>
            </Col>
        </Row>
        </>
    );
}
 
export default BloodPressureFormTwo;