import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import NewProfile from './shared-views/Profile/NewProfile';

const TemplateProfile = () => {
    return ( 
        <Container className="mt-5">
            <Row>
                <Col xs={12} md={12} lg={12}>
                    <NewProfile />
                </Col>
            </Row>
        </Container>    
     );
}
 
export default TemplateProfile;