import React, {useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import {Upload} from 'react-bootstrap-icons';
import SweetAlert2 from 'react-sweetalert2';
import { savePhysicalExamAction } from 'happ-redux/happ-actions/exams/examsActions';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getLastPhysicalExamAction } from 'happ-redux/happ-actions/exams/examsActions';

const edemaState = {
    edemaResult: -1,
    grado: -1,
    observationEdema: '',
    file: null,
}
const FormEdema = () => {
    const dispatch = useDispatch();
    const { patient } = useSelector(state => state.profile);
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    const [errorExamMessage, setErrorExamMessage] = useState({
        show: true,
        text: 'El archivo supera el limite de tamaño permitido',
        type: 'danger',
        icon: 'danger',
    });
    const hiddenFileInput = useRef(null);
    const handleClickImage = e => {
        hiddenFileInput.current.click();
      };
    const { 
        state,
        errors,
        handlerChange,
        handleChangeFile,
        handlerSubmit } = useValidatorForm(edemaState, saveObservationEdema, 'exam-edema');
    useEffect(() => {
        dispatch(getLastPhysicalExamAction(patient?._id, 'edema'));
    }, []);
    const { statusSavePhysicalExam, lastPhysicalExam, loadingSavesPhysical } = useSelector(state => state.exams);
    const {
        edemaResult,
        grado,
        observationEdema,
        file,
    } = state;
    function saveObservationEdema() {
        const body = new FormData();
        const params = {
            patientId: patient?._id,
            examName: 'edema',
            results: {
                edemaResult,
                grado,
            },
            observations: observationEdema,
        }
        body.append('file', file);
        body.append('body', JSON.stringify(params));
        dispatch(savePhysicalExamAction(body));
        state.edemaResult = -1;
        state.grado = -1;
        state.observationEdema = '';
        state.file = null;
    }
    return ( 
        <>
            {statusSavePhysicalExam !== null && statusSavePhysicalExam ? (
                <SweetAlert2
                    {...examMessage}
                />
            ) : null} 
            {statusSavePhysicalExam !== null && !statusSavePhysicalExam ? (
                <SweetAlert2
                    {...errorExamMessage}
                />
            ) : null}
            {loadingSavesPhysical ? (
                <div className='text-center mt-5 mb-5'>
                    <Spinner
                        animation="border"
                    />
                    <p className='mt-2'><strong>Guardando examen por favor espere.</strong></p>
                </div>
            ) : (
                <Row className="justify-content-md-center">
                    <Col md={7} sm={7} lg={7} className="content-form">
                        <div className='text-center'>
                            <Form.Label className='mb-5 title-physical-exams'>Examen Edema</Form.Label>
                        </div>
                        <Form
                            onSubmit={handlerSubmit}
                        >
                            <Col xs={12} md={12} lg={12} >
                                <div className='title-exam mb-5'>
                                    <div className='title-exam image-relative'>
                                        <Button
                                            onClick={handleClickImage}
                                            className='btn-upload-file'
                                        >
                                            <Upload />
                                            <p>Seleccionar Imagen</p>
                                        </Button>
                                        <Form.Control 
                                            type='file'
                                            name='file'
                                            ref={hiddenFileInput}
                                            onChange={handleChangeFile}
                                            style={{display:'none'}} 
                                        />
                                        <p className='file-name'>{file ? file.name : null}</p>
                                    </div>
                                    <div className='content-info-2 margin-info'>
                                        <div>
                                            <h6>Edema </h6>
                                            <Form.Check
                                                inline
                                                label="Si"
                                                value={1}
                                                checked={String(edemaResult) === "1"}
                                                onChange={handlerChange}
                                                name="edemaResult"
                                                type="radio"
                                                
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                value={0}
                                                checked={String(edemaResult) === "0"}
                                                onChange={handlerChange}
                                                name="edemaResult"
                                                type="radio"
                                                
                                            />
                                        </div>
                                        <div>
                                            <h6>Grado </h6>
                                            <Form.Check
                                                inline
                                                label="+"
                                                value={1}
                                                checked={String(grado) === "1"}
                                                onChange={handlerChange}
                                                name="grado"
                                                type="radio"
                                                
                                            />
                                            <Form.Check
                                                inline
                                                label="++"
                                                checked={String(grado) === "2"}
                                                value={2}
                                                onChange={handlerChange}
                                                name="grado"
                                                type="radio"
                                                
                                            />
                                            <Form.Check
                                                inline
                                                label="+++"
                                                value={3}
                                                checked={String(grado) === "3"}
                                                onChange={handlerChange}
                                                name="grado"
                                                type="radio"
                                                
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Observaciones</Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        rows={3}
                                        value={observationEdema ? observationEdema : ''}
                                        onChange={handlerChange}
                                        name="observationEdema" 
                                    />
                                </Form.Group>
                                {errors.edemaResult || errors.grado || errors.observationEdema ? (
                                    <div className='error-group'>
                                        <small>Acciones requeridas: </small>
                                        {errors.edemaResult ? (
                                            <p>{errors.edemaResult}</p>
                                        ) : null}
                                        {errors.observationEdema ? (
                                            <p>{errors.observationEdema}</p>
                                        ) : null}
                                        {errors.file ? (
                                            <p>{errors.file}</p>
                                        ) : null}
                                    </div>
                                ) : null}                  
                                <Button 
                                    variant="primary"
                                    className='btn-save-observation' 
                                    type="submit"
                                >
                                    {'Guardar'}
                                </Button>
                            </Col>
                        </Form>
                    </Col>
                    <Col md={7} sm={7} lg={7} className="content-form text-center">
                    {lastPhysicalExam ? (
                        <div>
                            <a
                                className='btn btn-primary text-center'
                                href={lastPhysicalExam.documentUrl}
                                target="_blank"
                            >
                                Ver ultimo examen cargado de {patient?.firstName}
                            </a>
                        </div>
                    ) : null}
                </Col>
                </Row>
            )}
        </>
    );
}
 
export default FormEdema;