import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import patientProfileReducer from './patient-profile/patientProfileReducer';
import surveyReducer from './survey/surveyReducer';
import examsReducer from './exams/examsReducer';
import boxReducer from './box/boxReducer';
import validateIdentityReducer from './validateIdentity/validateIdentityReducer';
import patientListReducer from './patient-list/patientListReducer';
import consentSignatureReducer from './consent-signature/consentSignatureReducer';

export default combineReducers({
    auth:       authReducer,
    profile:    patientProfileReducer,
    survey:     surveyReducer,
    exams:      examsReducer,
    box:        boxReducer,
    identity:   validateIdentityReducer,
    patients:   patientListReducer,
    consentSignature: consentSignatureReducer,
});