import axiosClient from 'happ-config/axios';

const getBoxList = async (token, admin, medicalStage) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/box/get-box-list-by-admin/${admin}/${medicalStage}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const assignBoxToPatient = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post('/patient-box', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getBoxList,
    assignBoxToPatient
}