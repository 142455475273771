import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import whiteLogo from 'assets/img/logo-happ-white.svg';
import { signOffAction } from 'happ-redux/happ-actions/auth/authActions';

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const closeSession = e => {
      e.preventDefault();
      dispatch(signOffAction(navigate));
    }
    const {loginProfile} = useSelector(state => state.auth);
    return ( 
        <Navbar bg="light" expand="lg" className="happ-background-color white-color" sticky="top">
          <Container fluid>
            <Link to="/">
              <img src={whiteLogo} alt="description"/>
            </Link>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '100px' }}
                navbarScroll
              >
                {loginProfile && (loginProfile?.role === 1 || loginProfile?.role === 2 || loginProfile?.role === 8) ? (
                  <>
                    <a className="navbar-expand-lg navbar-nav nav-link" href='/'>Screening</a>
                    <a className="navbar-expand-lg navbar-nav nav-link" href='/nuevo-paciente'>Crear Paciente</a>
                    <a className="navbar-expand-lg navbar-nav nav-link" href='/listado-examenes'>Listado de exámenes médicos</a>
                     <a className="navbar-expand-lg navbar-nav nav-link" href='/segunda-toma-presion'>Segunda toma de presión</a>
                    {/*<a className="navbar-expand-lg navbar-nav nav-link" href='/validar-identidad'>Validar indentidad</a> */}
                  </>
                ): null }
                {loginProfile && (loginProfile?.role === 1 || loginProfile?.role === 3 || loginProfile?.role === 4) ? (
                  <>
                    <a className="navbar-expand-lg navbar-nav nav-link" href='/'>Screening</a>
                    <a className="navbar-expand-lg navbar-nav nav-link" href='/segunda-toma-presion'>Segunda toma de presión</a>
                    {/* <a className="navbar-expand-lg navbar-nav nav-link" href='/listado-operativo'>Listado de operativo</a>                */}
                  </>
                ): null }
              </Nav>
              <Nav>
                <NavDropdown title="Mi cuenta" id="collasible-nav-dropdown">
                  <Button
                    className="btn-outline"
                    onClick={closeSession}
                  >
                    Cerrar sesión
                  </Button>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    );
}
 
export default Sidebar;