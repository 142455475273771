import { 
    INIT_SAVE_SURVEY, 
    SAVE_SURVEY, 
    ERROR_SAVE_SURVEY,
    ERROR_TOKEN,
    CLEAN_STATUS_SURVEY, 
} from "happ-redux/happ-types";

const initState = {
    loading: false,
    isSaveSurvey: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initState, action){
    switch(action.type) {
        case INIT_SAVE_SURVEY:
            return {
                ...state,
                loading: true
            }
        case SAVE_SURVEY: 
            return {
                ...state,
                loading: false,
                isSaveSurvey: true,
            }
        case ERROR_SAVE_SURVEY: 
            return {
                ...state,
                loading: false,
                isSaveSurvey: false,
            }
        case ERROR_TOKEN:
            localStorage.clear(); 
            return {
                ...state
            }
        case CLEAN_STATUS_SURVEY:
            return {
                isSaveSurvey: null,
            }            
        default:
            return state;
    }
}