import axiosClient from 'happ-config/axios';

const getHealthBenefitByCode = async (token, code) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/health-benefit/get-health-benefit-by-code/${code}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getHypertensionByCode = async (token, code, patient) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/health-benefit/get-hypertension-two-id/${code}/${patient}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getHealthBenefitsByPatient = async (token, patient, medicalStage) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/patient-health-benefit/get-healthBenefits-by-patient/${patient}/${medicalStage}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;   
    }
}

const getBloodPressureInfoPatient = async (token, dni) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/patients/find-blood-pressure-info-patient-by-dni/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;   
    }
}

const examsListChecked = async (token, exams) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post('/patient-health-benefit/exam-list-checked', exams, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;  
    }
}

// const calculateMedicalAgendaPatient = async (token, body) => {
//     try {
//         const headers = {
//             'Content-Type': 'application/json',
//             'authtoken': token
//         };
//         const response = await axiosClient.post('/patient-healthbenefit-result/enableBox', body, {headers});
//         return response;
//     } catch (error) {
//         const {response} = error;
//         return response; 
//     }
// }

const calculateMedicalAgendaPatient = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post('/patient-healthbenefit-result/generateExamSchedule', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response; 
    }
}

const validateMedicalAgendaPatient = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post('/patient-health-benefit/validate-exams-are-done', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response; 
    }
}

const saveExamParamsByPatient = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post('/patient-healthbenefit-result', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const saveHypertensionExamParamsByPatient = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post('/patient-healthbenefit-result/tomaPresion2', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const saveHypertensionExamParamsByPatientBeta = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.post('/patient-healthbenefit-result/tomaPresion2_beta', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const savePhysicalExam = async (token, body) => {
    try {
        const headers = {
            'Content-Type': 'multipart/form-data',
        };
        const response = await axiosClient.post('/teledoc/upload-observations-exams', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getLastPhysicalExam = async (token, patient, examName) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/teledoc/get-last-physical-exam-by-type/${patient}/${examName}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getHealthBenefitsByPatientDni = async (token, dni) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/patient-health-benefit/find-by-dni/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;   
    }
}

const getExamResultsHistory = async (token, dni) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/patients/get-results-exams-history/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;   
    }
}

const saveProstateAntigen = async (body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.post('/prostate-antigen', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getProstateAntigenResultByDni = async (dni) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/prostate-antigen/get-result-by-patient-dni/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getHealthBenefitByCode,
    saveExamParamsByPatient,
    saveHypertensionExamParamsByPatient,
    getHealthBenefitsByPatient,
    getHypertensionByCode,
    examsListChecked,
    calculateMedicalAgendaPatient,
    validateMedicalAgendaPatient,
    savePhysicalExam,
    getLastPhysicalExam,
    saveHypertensionExamParamsByPatientBeta,
    getHealthBenefitsByPatientDni,
    getBloodPressureInfoPatient,
    getExamResultsHistory,
    saveProstateAntigen,
    getProstateAntigenResultByDni,
}