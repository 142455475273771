import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { 
    sendCodeConsentSignatureAction,
    getConsentSignatureStatusAction,
    getPreviewPdfConsentSignatureAction, 
    confirmTermsAction,
    getSignedPdfConsentSignatureAction,
    cleanConsetSignatureAction,
    confirmPaperTermsAction
} from "happ-redux/happ-actions/consent-signature/consentSignatureAction";
import { updateProfileDataPatientAction } from "happ-redux/happ-actions/patient-profile/patientProfileActions";
import moment from 'moment';

const useConsentSignature = (initialState) => {
    const dispatch = useDispatch();
    const [state, setState] = useState(initialState);
    const [adminInfo, setAdminInfo] = useState({});
    const [errors, setErrors] = useState({});
    const [sendCodeProcess, setSendCodeProcess] = useState(false);
    const [pdfPreviewProcess, setPdfPreviewProcess] = useState(false);
    const [pdfSignedProcess, setPdfSignedProcess] = useState(false);
    const [spinnerSubmit, setSpinnerSubmit] = useState(false);
    const [validateCodeProcess, setValidateCodeProcess] = useState(false);
    const [confirmPaperTerms, setConfirmPaperTerms] = useState(false);
    const [resendCodeProcess, setResendCodeProcess] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showErrorOtp, setShowErrorOtp] = useState(false);
    const [showErrorConfirmPaperTerm, setShowErrorConfirmPaperTerm] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [showAlertPdf, setShowAlertPdf] = useState(false);
    const { patient } = useSelector(state => state.profile);
    const { 
        consentSignature, 
        loading, 
        sendCodeStatus,
        pdfPreview,
        confirmCodeStatus, 
        pdfSigned,
        errorServer,
        confirmPaperCodeStatus,
        messageError,
    } = useSelector(state => state.consentSignature);

    useEffect(() => {
        if (consentSignature !== null) {
          let phone = '';
          if (patient?.phone.split(' ', 2).length === 2) {
              phone = patient?.phone.split(' ', 2)[1];
          } else {
              phone = patient?.phone;
          }
          setState({
              ...state,
              phone,
              email: patient?.email,
              consentStatus: consentSignature.type === 'none' ? '' : 'complete',
              consentType: consentSignature.type !== 'none' ? consentSignature.type : '',
              sendCodeStatus: false,
          });
          if (consentSignature.type === 'paper') {
            setAdminInfo({
                ...adminInfo,
                name: consentSignature.signedBy.name,
                dni: consentSignature.signedBy.dni,
                date: moment.utc(consentSignature.signedAt).format('DD-MM-YYYY'),
            });
          }
          dispatch(getPreviewPdfConsentSignatureAction(patient?._id));
        }
    }, [consentSignature]);

    useEffect(() => {
      if (!loading && confirmPaperCodeStatus !== null) {
        setSpinnerSubmit(false);
        if (confirmPaperCodeStatus) {
            setShowErrorConfirmPaperTerm(false);
            setState({
                ...state,
                consentType: 'paper',
                consentStatus: 'complete',
            });
            dispatch(getConsentSignatureStatusAction(patient?._id));
        } else {
            setShowErrorConfirmPaperTerm(true);
            setState({
                ...state,
                consentType: 'paper',
                consentStatus: 'pending',
            });
        }

      }
    }, [loading, confirmPaperCodeStatus]);
    

    useEffect(() => {
      if (confirmPaperTerms) {
        dispatch(confirmPaperTermsAction(patient?._id));
        setSpinnerSubmit(true);
        setShowErrorConfirmPaperTerm(false);
        setConfirmPaperTerms(false);
      }
    }, [confirmPaperTerms])
    

    useEffect(() => {
      if (
            !loading && 
            sendCodeStatus !== null 
        ) {
            if (sendCodeStatus === false) {
                setShowErrorAlert(true);
            }
            if (resendCodeProcess) {
                setResendCodeProcess(false);
                setShowAlert(true);
            }
            setSpinnerSubmit(false);
            setState({
                ...state,
                sendCodeStatus,
                consentStatus: 'pending',
                consentType: 'digital',
            });
        }
    }, [loading, sendCodeStatus]);
    
    useEffect(() => {
      if (sendCodeProcess) {
        if (Object.keys(errors).length === 0){
            const body = {
                email: state.email,
                phone: `${state.phoneCode} ${state.phone}`,
            }
            dispatch(sendCodeConsentSignatureAction(patient?._id, body));
            dispatch(updateProfileDataPatientAction(state.phoneCode, state.phone, state.email));
            setSpinnerSubmit(true);
            setSendCodeProcess(false);
            setShowAlert(false);
            setShowErrorAlert(false);
        } else {
            setSendCodeProcess(false);
        }
      }
    }, [sendCodeProcess]);

    useEffect(() => {
      if (pdfSignedProcess) {
        if (pdfSigned !== null) {
            const link = document.createElement('a');
            link.href = pdfSigned;
            link.target = '_blank';
            link.rel = 'noopener noreferrer'; // Seguridad adicional
            link.click();
        } else {
            setShowAlertPdf(true);
        }
        setPdfSignedProcess(false);
      }
    }, [pdfSignedProcess])
    
    useEffect(() => {
        if (resendCodeProcess) {
            setShowAlert(false);
            setSpinnerSubmit(true);
            setResendCodeProcess(true);
            const body = {
                email: state.email,
                phone: `${state.phoneCode} ${state.phone}`,
            }
            dispatch(sendCodeConsentSignatureAction(patient?._id, body));
        } else {
            setResendCodeProcess(false);
            setShowAlert(false);
        }
    }, [resendCodeProcess]);

    useEffect(() => {
        if (!loading && confirmCodeStatus !== null) {
            setSpinnerSubmit(false);
            if (confirmCodeStatus === true) {
                setState({
                    ...state,
                    consentType: 'digital',
                    consentStatus: 'complete',
                });
            } else {
                setShowErrorOtp(true);
                setState({
                    ...state,
                    consentType: 'digital',
                    consentStatus: 'pending',
                });
            }
        }
    }, [loading, confirmCodeStatus]);
    

    useEffect(() => {
      if (validateCodeProcess) {
        if (Object.keys(errors).length === 0) {
            setSpinnerSubmit(true);
            const body = {
                code: state.consentCode,
            };
            setShowErrorOtp(false);
            dispatch(confirmTermsAction(patient?._id, body));
            setValidateCodeProcess(false);
        } else {
            setValidateCodeProcess(false);
        }
      }
    }, [validateCodeProcess]);
    
    
    const checkForEmptyInputs = e => {
        const { phoneCode, phone, email } = state;
        let errors = {};
        if (phoneCode.length < 2) {
            errors.phoneCode = 'Codigo del teléfono debe tener al menos dos dígitos, Ej: 56';
        }
        if (phone.length < 9) {
            errors.phone = 'El número de teléfono de tener al menos 9 dígitos'
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
            errors.email = 'El email es requerido y de debe ser un email valido'
        }
        setErrors(errors);
    }

    const getPreviewPdf = e => {
        if (pdfPreview !== null) {
            // const url = URL.createObjectURL(pdfPreview);
            // console.log(url);
            // const link = document.createElement('a');
            // link.href = fileUrl;
            // link.target = '_blank';
            // link.rel = 'noopener noreferrer'; // Seguridad adicional
            // link.click();

            // const blob = new Blob(JSON.stringify(pdfPreview), { type: "application/pdf" });
            // const url = URL.createObjectURL(blob);
            // window.open(url, '_blank');

        }

    }

    const getSignedPdf = e => {
        e.preventDefault();
        dispatch(getSignedPdfConsentSignatureAction(patient?._id));
        setPdfSignedProcess(true);
        setShowAlertPdf(false)
    }

    const sendConsentSignatureCodeSMS = e => {
        e.preventDefault();
        checkForEmptyInputs();
        setSendCodeProcess(true);
    }
    
    const resendConsentSignatureCodeSMS = e => {
        e.preventDefault();
        setResendCodeProcess(true);
    }

    const changePhone = () => {
        let phone = '';
        if (patient?.phone.split(' ', 2).length === 2) {
            phone = patient?.phone.split(' ', 2)[1];
        } else {
            phone = patient?.phone;
        }
        setState({
            ...state,
            phone,
            email: patient?.email,
            consentStatus: 'pending',
            consentType: 'digital',
            sendCodeStatus: false,
        });
    }

    const validateConsentSignatureCode = e => {
        e.preventDefault();
        let errors = {};
        const { consentCode } = state;
        if (consentCode.length < 4) {
            errors.consentCode = 'El Código debe ser de 4 dígitos';
        }
        setErrors({});
        setErrors(errors);
        setValidateCodeProcess(true);
    }

    const confirmPaperTerm = e => {
        e.preventDefault();
        setConfirmPaperTerms(true);
    }

    const handlerChangeNumber = e => {
        const onlyContainsNumbers = (str) => /^\d+$/.test(str);
        if (onlyContainsNumbers(e.target.value) || e.target.value === '') {
            setState({
                ...state,
                [e.target.name]: e.target.value,
            });
        }
    }

    const handlerChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const handlerSelectConsentType = (type) => {
        if (type === 'digital') {
            setState({
                ...state,
                consentStatus: 'pending',
                consentType: 'digital',
                sendCodeStatus: false,
            });
        } else {
            setState({
                ...state,
                consentStatus: 'pending',
                consentType: 'paper',
                sendCodeStatus: false,
            });
        }
    }

    const handlerCancelCansentPaper = () => {
        setState({
            ...state,
            consentStatus: '',
        })
    }

    return {
        state,
        errors,
        showAlert,
        spinnerSubmit,
        resendCodeProcess,
        showAlertPdf,
        showErrorAlert,
        messageError,
        showErrorOtp,
        showErrorConfirmPaperTerm,
        adminInfo,
        changePhone,
        getPreviewPdf,
        getSignedPdf,
        handlerChangeNumber,
        handlerCancelCansentPaper,
        handlerChange,
        handlerSelectConsentType,
        sendConsentSignatureCodeSMS,
        resendConsentSignatureCodeSMS,
        validateConsentSignatureCode,
        confirmPaperTerm,
    }
}

export default useConsentSignature;