import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Col, Row, Form, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import 'react-select-search/style.css'
import SelectSearch from 'react-select-search';
import ErrorMessage from 'happ-components/Alerts/ErrorMessage';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import { createPatientAction, getCompaniesAction, getBranchesAction } from 'happ-redux/happ-actions/patient-profile/patientProfileActions';

let initState = {
    firstName: '',
    lastName: '',
    lastName2: '',
    email: '',
    pre: '',
    phone: '',
    gender: '',
    healthForecast: '',
    birthDay: '',
    age: '',
    street: '',
    commune: '',
    region: '',
    company: '',
    dni: '',
    position: '',
    branchOffice: '',
}

const NewProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isCreatePatient, createPatientMessage } = useSelector(state => state.profile);
    const [updateProfileMessage, setUpdateProfileMessage] = useState({
        show: true,
        text: 'Paciente creado exito',
        type: 'success',
        icon: 'success',
    });

    
    useEffect(() => {
        dispatch(getCompaniesAction());
        dispatch(getBranchesAction());
    }, []);
    const { companies } = useSelector(state => state.profile);

    useEffect(() => {
      if (isCreatePatient) {
        cleanInputs();
      }
    }, [isCreatePatient]);
    

    const {
        state,
        errors,
        comunes,
        stateReg,
        filterBranches,
        handlerChange,
        handlerChangeBirthDay,
        handlerSubmit,
        handlerChangeReg,
        handlerChangeCompany,
        handlerPhone,
        handlerChangeDni
    } = useValidatorForm(initState, sendResults, 'new-profile');
    const cleanInputs = () => {
        state.firstName = '';
        state.lastName = '';
        state.lastName2 = '';
        state.email = '';
        state.phone = '';
        state.gender = '';
        state.healthForecast = '';
        state.birthDay = '';
        state.street = '';
        state.commune = '';
        state.region = '';
        state.company = '';
        state.dni = '';
        state.branchOffice = '';
    }
    const {
        firstName,
        lastName,
        lastName2,
        email,
        pre,
        phone,
        gender,
        healthForecast,
        birthDay,
        street,
        commune,
        region,
        company,
        dni,
        branchOffice,
    } = state;
    function sendResults() {
        const formattedPhoneSlice = phone.slice(pre.length, phone.length);
        const fullNametrim = `${firstName}${lastName}${lastName2}`;
        const body = {
            firstName,
            lastName: `${lastName} ${lastName2}`,
            fullName: `${firstName} ${lastName} ${lastName2}`, 
            email: email === '' ? `${fullNametrim}${dni}@happ.cl` : email,
            phone: `${pre} ${formattedPhoneSlice}`,
            password: '',
            gender,
            healthForecast,
            position: "auto-creacion",
            birthDay,
            dni,
            dniWithoutDv: dni.substring(0, dni.length - 2),
            company,
            branchOfficeId: branchOffice,
            address: [{
                commune,
                region,
                street,
                alias: 'casa',
            }],
            termsAndConditionsCheck: false,
            status: true
        }
        dispatch(createPatientAction(body));
    }

    return ( 
        <Form
        onSubmit={handlerSubmit}
    >
        {isCreatePatient !== null && isCreatePatient ? (
            <SweetAlert2
                {...updateProfileMessage}
            />
        ) : null}
        {isCreatePatient !== null && !isCreatePatient ?(
            <Row className="align-items-center mt-5">
                <ErrorMessage 
                    message={createPatientMessage}
                />
            </Row>
        ): null}
        <Row className="mb-3">
            <Col sm={2}  className="my-1 col-input">
                <Form.Group  controlId="firstName">
                    <Form.Label>Rut</Form.Label>
                    <Form.Control 
                        type="text"
                        onChange={handlerChangeDni}
                        value={dni ? dni : ''} 
                        name="dni"  
                        placeholder='Rut'
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.dni && errors.dni}</p>
            </Col>
            <Col sm={2}  className="my-1 col-input">
                <Form.Group  controlId="firstName">
                    <Form.Label>Nombres</Form.Label>
                    <Form.Control 
                        type="text"
                        onChange={handlerChange}
                        value={firstName ? firstName : ''} 
                        name="firstName" 
                        placeholder="Nombres" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.firstName && errors.firstName}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="lastName">
                    <Form.Label>Apellido Paterno</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handlerChange}
                        value={lastName ? lastName : ''} 
                        name="lastName" 
                        placeholder="Apellido Paterno" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.lastName && errors.lastName}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="lastName2">
                    <Form.Label>Apellido Materno</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handlerChange}
                        value={lastName2 ? lastName2 : ''}  
                        name="lastName2" 
                        placeholder="Apellido Materno" 
                    />
                </Form.Group>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group className="mb-3" controlId="birthDay">
                    <Form.Label>Fecha de nacimiento</Form.Label>
                    <Form.Control 
                        type="date"
                        onChange={handlerChangeBirthDay}
                        value={birthDay ? birthDay : ''} 
                        name="birthDay" 
                        placeholder="" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.birthDay && errors.birthDay}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="gender">
                <Form.Label>Género</Form.Label>
                <Form.Select 
                    name="gender" 
                    onChange={handlerChange}
                    value={gender ? gender : ''} 
                >
                    <option value="">Seleccione género</option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                </Form.Select>
                </Form.Group>
                <p className='p-error-profile-col'>{errors.gender && errors.gender}</p>
            </Col>
        </Row>
        <Row className="mb-3">
            <Col sm={6} className="my-1 col-input">
                <Form.Label>Empresa</Form.Label>
                <SelectSearch 
                    options={companies ? companies.map(item => (
                        {name: item.name, value: item._id}
                    )) : []} 
                    search
                    name="company" 
                    onChange={handlerChangeCompany}
                    value={company} 
                    placeholder="Selecciona una empresa" 
                />
                <p className='p-error-profile-col' style={ { bottom: '-35px' } }>{errors.company && errors.company}</p>
            </Col>
            <Col sm={3} className="my-1 col-input">
                <Form.Group controlId="branchOffice">
                    <Form.Label>Sucursal</Form.Label>
                    <Form.Select 
                        name="branchOffice" 
                        onChange={handlerChange}
                        value={branchOffice ? branchOffice : ''} 
                    >
                            <option value="">Seleccione sucursal</option>
                            {filterBranches ? filterBranches.map(item => (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            )): null}
                    </Form.Select>
                </Form.Group>
                <p className='p-error-profile-col mt-2' style={ { bottom: '-35px' } }>{errors.branchOffice && errors.branchOffice}</p>
            </Col>
            <Col sm={3} className="my-1 col-input">
                <Form.Group  controlId="healthForecast">
                    <Form.Label>Previsión de Salud</Form.Label>
                    <Form.Select 
                        name="healthForecast" 
                        onChange={handlerChange}
                        value={healthForecast !== 'sin prevision' ? healthForecast : ''} 
                    >
                            <option value="">Seleccione una previsión de salud</option>
                            <option value="Fonasa">Fonasa</option>
                            <option value="Isapre Colmena">Isapre Colmena</option>
                            <option value="Isapre Cruz Blanca">Isapre Cruz Blanca</option>
                            <option value="Isapre Banmédica">Isapre Banmédica</option>
                            <option value="Isapre Consalud">Isapre Consalud</option>
                            <option value="Isapre Vida tres">Isapre Vida tres</option>
                            <option value="Isapre Esencial">Isapre Esencial</option>
                            <option value="Isapre Nueva masvida">Isapre Nueva masvida</option>
                    </Form.Select>
                </Form.Group>
                <p className='p-error-profile-col'>{errors.healthForecast && errors.healthForecast}</p>
            </Col>
        </Row>
        <Row className="mb-3 mt-5">
            <Col sm={2} className="my-1 col-input">
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Correo Electronico</Form.Label>
                    <Form.Control 
                        type="text" 
                        onChange={handlerChange}
                        value={email ? email : ''} 
                        name="email" 
                        placeholder="Correo Electronico" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.email && errors.email}</p>
            </Col>
            <Col sm={3} className="my-1 col-input">
                <Form.Group className="mb-3 form-phone" controlId="phone">
                    <Form.Label>Teléfono</Form.Label>
                    <PhoneInput
                        country={'cl'}
                        inputProps={{
                            name: 'phone',
                            type: 'text',
                            required: true,
                            autoFocus: true,
                        }}
                        value={phone? phone : ''}
                        onChange={handlerPhone}
                    />
                </Form.Group>    
                <p className='p-error-profile-col'>{errors.phone && errors.phone}</p>
            </Col>  
            <Col sm={3} className="my-1 col-input">
                <Form.Group className="mb-3" controlId="street">
                    <Form.Label>Dirección</Form.Label>
                    <Form.Control 
                        type="text"
                        onChange={handlerChange}
                        value={street? street : ''} 
                        name="street" 
                        placeholder="Dirección" 
                    />
                </Form.Group>
                <p className='p-error-profile-col'>{errors.street && errors.street}</p>
            </Col>
            <Col sm={2} className="my-1 col-input">
                <Form.Group  controlId="region">
                <Form.Label>Región</Form.Label>
                <Form.Select 
                    name="region"
                    onChange={handlerChangeReg}
                    value={region ? region : ''}
                >
                    <option value="sin region">Selecione una región</option>
                    {stateReg && Object.keys(stateReg).length > 0 ? (
                        stateReg.map(reg => (
                            <option key={reg.id} value={reg.name}>{reg.name}</option>
                        ))    
                    ) : null}
                </Form.Select>
                </Form.Group>
                <p className='p-error-profile-col'>{errors.region && errors.region}</p>
            </Col>
                <Col sm={2} className="my-1 col-input">
                    <Form.Group  controlId="commune">
                    <Form.Label>Comuna</Form.Label>
                    <Form.Select 
                        name="commune" 
                        value={commune ? commune : ''}
                        onChange={handlerChange}
                        >
                        <option value="sin comuna">Selecione una comuna</option>
                        {comunes && Object.keys(comunes).length > 0 ? (
                        comunes.map(comuna => (
                            <option key={comuna.id} value={comuna.name}>{comuna.name}</option>

                        ))
                        ) : null}
                    </Form.Select>
                    </Form.Group>
                    <p className='p-error-profile-col'>{errors.commune && errors.commune}</p>
                </Col>
        </Row>
        <Row className="align-items-center mt-5">
            <Button 
                variant="primary" 
                type="submit"
            >
                Crear Paciente 
            </Button>
        </Row>
    </Form>
     );
}
 
export default NewProfile;