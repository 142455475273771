import { Col, Row, Button } from 'react-bootstrap';


const ConfirmConsentSignaturePaper = ({handlerCancelCansentPaper, confirmPaperTerm}) => {
    return (
        <Row>
            <Col sm={6} className='col-consent-confirm-content'>
                <div className='confirm-consent-content justify-content-center text-center'>
                    <h4>Confirmación de Consentimiento en formato Impreso</h4>
                    <p><strong>¿Estás seguro de que deseas marcar al paciente como que ha completado el consentimiento en formato físico?</strong></p>
                    <p>Por favor, asegúrate de que el consentimiento se haya obtenido directamente del paciente y de que esté debidamente almacenado para su registro posterior.</p>
                    <div>
                        <Button
                            className='btn-c-d'
                            onClick={confirmPaperTerm}
                        >Si, Confirmo</Button>
                        <Button
                            className='btn-c-p'
                            onClick={handlerCancelCansentPaper}
                        >Cancelar</Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
}
 
export default ConfirmConsentSignaturePaper;