export function getInputToValidate(origin, input) {
    const typeOrigins = {
        'bloodPressure': validateBloodPressureRanges(input.sistolica, input.diastolica),
        'hemoglucotest': validateHemoglucotestRanges(input.glycemia),
        'antropometria': validateImcRanges(input.height, input.weight, input.circumference),
    }
    return typeOrigins[origin] || null;
} 

function validateBloodPressureRanges(sistolica, diastolica) {
    let errors = {};
    if (Number(sistolica) < 70 || Number(sistolica) > 230) {
        errors.sistolica = 'La Sistólica esta fuera del rango minimo y maximo establecido, (entre 70 y 230)';
    }
    if (Number(diastolica) < 50 || Number(diastolica) > 160) {
        errors.diastolica = 'La Diastólica esta fuera del rango minimo y maximo establecido, (entre 50 y 160)';
    }
    return errors;
}

function validateHemoglucotestRanges(glycemia) {
    let errors = {};
    if (Number(glycemia) < 45 || Number(glycemia) > 600) {
        errors.glycemia = 'La glicemia esta fuera del rango minimo y maximo establecido (entre 45 y 600)';
    }
    return errors;
}

function validateImcRanges(height, weight, circumference) {
    let errors = {};
    if (Number(weight) < 25 || Number(weight) > 200) {
        errors.weight = 'El peso esta fuera del rango minimo y maximo establecido, (25 y 200 cm)';
    }
    if (Number(height) < 110 || Number(height) > 260) {
        errors.height = 'La altura esta fuera del rango minimo y maximo establecido, (110 y 260 cm)';
    }
    if (Number(circumference) < 55 || Number(circumference) > 200) {
        errors.circumference = 'La circunferencia esta fuera del rango minimo y maximo establecido, (55 y 200 cm)';
    }
    return errors;
} 