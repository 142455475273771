import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
import {Col, Row, Form, Button} from 'react-bootstrap';

import InfoPatientNav from 'happ-components/Navbar/InfoPatientNav';
import { getHealthBenefitsByPatientAction, healthBenefitUpdateListAction } from 'happ-redux/happ-actions/exams/examsActions';

const LaboratoryExams = () => {
    const [examArray, setExamArray] = useState([]);
    const [activeEmptyMessage, setActiveEmptyMessage] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Examenes procesados con exito',
        type: 'success',
        icon: 'success',
    });
    const {patient, currentMedicalStage} = useSelector(state => state.profile);
    useEffect(() => {
        dispatch(getHealthBenefitsByPatientAction(navigate, patient?._id, currentMedicalStage?._id));
    }, [])    
    const {examList, examListError, updateExamList} = useSelector(state => state.exams);
    const handlerChangeExam = _id => e => {
        if (e.target.checked) {
            setExamArray([...examArray, _id])
        } else {
            const examIndex = examArray.indexOf(_id);
            const current = examArray;
            current.splice(examIndex, 1);
            setExamArray(current);
        }
    }
    
    const sendExamListChecked = e => {
        e.preventDefault();
        if (examArray.length) {
            dispatch(healthBenefitUpdateListAction(examArray));
        } else {
            setActiveEmptyMessage(true);
            setTimeout(() => {
                dispatch(setActiveEmptyMessage(false));
              }, 2000);
        }
    }
    return ( 
        <>
        <InfoPatientNav />
        {updateExamList ? (
            <SweetAlert2
                {...examMessage}
            />
        ) : null}
        {activeEmptyMessage ? (
            <Row className="align-items-center mt-5">
                <p className='error-save'>Debe seleccionar un examen para procesar</p>
            </Row>
        ) : null}
        <Row className="justify-content-md-center">
            <Col md={9} sm={9} lg={9} className="content-survey">
            <Col md={12} sm={12} lg={12}>
                <h4>Exámenes diagnósticos y complementarios</h4>
            </Col>
                <Form>
                    <Row className='mb-3 header-info-doc'>
                        {!examListError && examList ? examList.map(item => (
                            <Col md={12} sm={12} lg={12} className="col-question" key={item._id}>
                                <Form.Check
                                    inline
                                    className='check-survey'
                                    label={item.healthBenefit.name}
                                    value={true}
                                    onChange={handlerChangeExam(item._id)}
                                    name="q1"
                                    type="checkbox"
                                />
                            </Col>
                        )) : null}
                        <Col md={12} sm={12} lg={12} className="mt-3">
                            <Button
                                type='submit'
                                onClick={sendExamListChecked}
                                className='btn btn-primary btn-survey'
                            >Guardar</Button>
                        </Col>   
                    </Row>        
                </Form>
            </Col>
        </Row>
    </>
     );
}
 
export default LaboratoryExams;