import React, {useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import {Upload} from 'react-bootstrap-icons';
import SweetAlert2 from 'react-sweetalert2';
import { savePhysicalExamAction } from 'happ-redux/happ-actions/exams/examsActions';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForm';
import { getLastPhysicalExamAction } from 'happ-redux/happ-actions/exams/examsActions';
const ecgState = {
    normalEcg: -1,
    observationEcg: '',
    file: null,
}
const FormEcg = () => {
    const dispatch = useDispatch();
    const { patient } = useSelector(state => state.profile);
    const [examMessage, setExamMessage] = useState({
        show: true,
        text: 'Resultado registrado con exito',
        type: 'success',
        icon: 'success',
    });
    const [errorExamMessage, setErrorExamMessage] = useState({
        show: true,
        text: 'El archivo supera el limite de tamaño permitido',
        type: 'danger',
        icon: 'danger',
    });
    const hiddenFileInput = useRef(null);
    const handleClickImage = e => {
        hiddenFileInput.current.click();
    };
    const { 
        state,
        errors,
        handlerChange,
        handleChangeFile,
        handlerSubmit } = useValidatorForm(ecgState, saveObservationEcg, 'exam-ecg');
    const {normalEcg, observationEcg, file} = state;
    useEffect(() => {
        dispatch(getLastPhysicalExamAction(patient?._id, 'ecg'));
    }, []);
    const { statusSavePhysicalExam, lastPhysicalExam, loadingSavesPhysical } = useSelector(state => state.exams);
    function saveObservationEcg() {
        const body = new FormData();
        const params = {
            patientId: patient?._id,
            examName: 'ecg',
            results: {
                normalEcg,
            },
            observations: observationEcg,  
        }
        body.append('file', file);
        body.append('body', JSON.stringify(params));
        dispatch(savePhysicalExamAction(body));
        state.normalEcg = -1;
        state.observationEcg = '';
        state.file = null;
    }
    return ( 
        <>
            {statusSavePhysicalExam !== null && statusSavePhysicalExam ? (
                <SweetAlert2
                    {...examMessage}
                />
            ) : null}
            {statusSavePhysicalExam !== null && !statusSavePhysicalExam ? (
                <SweetAlert2
                    {...errorExamMessage}
                />
            ) : null}
            {loadingSavesPhysical ? (
                <div className='text-center mt-5 mb-5'>
                    <Spinner
                        animation="border"
                    />
                    <p className='mt-2'><strong>Guardando examen por favor espere.</strong></p>
                </div>
            ) : (
                <Row className="justify-content-md-center">
                    <Col md={7} sm={7} lg={7} className="content-form">
                            <div className='text-center'>
                                <Form.Label className='mb-5 title-physical-exams'>Examen ECG</Form.Label>
                            </div>
                            <Form
                                onSubmit={handlerSubmit}
                            >
                                <Col xs={12} md={12} lg={12}>
                                    <div className='title-exam mb-5'>
                                        <div className='title-exam image-relative'>
                                            <Button
                                                onClick={handleClickImage}
                                                className='btn-upload-file'
                                            >
                                                <Upload />
                                                <p>Seleccionar Documento</p>
                                            </Button>
                                            <Form.Control 
                                                type='file'
                                                name='file'
                                                ref={hiddenFileInput}
                                                onChange={handleChangeFile}
                                                style={{display:'none'}} 
                                            />
                                            <p className='file-name'>{file ? file.name : null}</p>
                                        </div>
                                        <div className='content-check-info margin-info'>
                                            <h6>ECG normal </h6>
                                            <Form.Check
                                                inline
                                                label="Si"
                                                value={1}
                                                checked={String(normalEcg) === "1"}
                                                onChange={handlerChange}
                                                name="normalEcg"
                                                type="radio"
                                            />
                                            <Form.Check
                                                inline
                                                label="No"
                                                value={0}
                                                checked={String(normalEcg) === "0"}
                                                onChange={handlerChange}
                                                name="normalEcg"
                                                type="radio"
                                            />
                                        </div>
                                    </div>
                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Observaciones</Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            rows={3}
                                            onChange={handlerChange}
                                            name='observationEcg'
                                            value={observationEcg ? observationEcg : ''} 
                                        />
                                    </Form.Group>
                                        {errors && errors.file ? (
                                            <div className='error-group'>
                                                <small>Acciones requeridas: </small>
                                                {errors.file ? (
                                                    <p>{errors.file}</p>
                                                ) : null}
                                            </div>
                                        ) : null}
                                        <Button 
                                            variant="primary"
                                            className='btn-save-observation' 
                                            type="submit"
                                        >
                                            {'Guardar'}
                                        </Button>
                                </Col>
                            </Form>
                    </Col>
                    <Col md={7} sm={7} lg={7} className="content-form text-center">
                        {lastPhysicalExam ? (
                            <div>
                                <a
                                    className='btn btn-primary text-center'
                                    href={lastPhysicalExam.documentUrl}
                                    target="_blank"
                                >
                                    Ver ultimo examen cargado de {patient?.firstName}
                                </a>
                            </div>
                        ) : null}
                    </Col>
                </Row>    
            )}
        </>
    );
}
 
export default FormEcg;