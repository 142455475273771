import { useState } from 'react';
import { Spinner, Col } from 'react-bootstrap';
import ConsentSignatureType from 'happ-components/ConsentSignatureStages/ConsentSignatureType';
import ConfirmConsentSignaturePaper from 'happ-components/ConsentSignatureStages/ConfirmConsentSignaturePaper';
import ConsentSignatureOk from 'happ-components/ConsentSignatureStages/ConsentSignatureOk';
import ConsentSignaturePaperOk from 'happ-components/ConsentSignatureStages/ConsentSignaturePaperOk';
import SendConsentSignatureCode from 'happ-components/ConsentSignatureStages/SendConsentSignatureCode';
import ValidateConsentSignatureCode from 'happ-components/ConsentSignatureStages/ValidateConsentSignatureCode';
import useConsentSignature from 'happ-custom-hooks/useConsentSignature/useConsentSignature';
import SweetAlert2 from 'react-sweetalert2';

const initState = {
    phoneCode: '56',
    phone: '',
    email: '',
    consentCode: '',
    consentType: '',
    consentStatus: '',
    sendCodeStatus: false,
}
const ConsentSignature = () => {
    const { 
        state, 
        errors,
        showAlert,
        spinnerSubmit,
        resendCodeProcess,
        showAlertPdf,
        showErrorAlert,
        messageError,
        showErrorOtp,
        showErrorConfirmPaperTerm,
        adminInfo,
        changePhone,
        getPreviewPdf,
        getSignedPdf,
        handlerChangeNumber,
        handlerSelectConsentType,
        handlerCancelCansentPaper,
        handlerChange,
        sendConsentSignatureCodeSMS,
        resendConsentSignatureCodeSMS,
        validateConsentSignatureCode,
        confirmPaperTerm,
    } = useConsentSignature(initState);
    const {
        phoneCode,
        phone,
        email,
        consentCode,
        consentType,
        consentStatus,
        sendCodeStatus
    } = state;
    const [errorMessage, setErrorMessage] = useState({
        show: true,
        text: 'Intente de nuevo en unos minutos',
        type: 'error',
        icon: 'error',
    });
    const [errorMessageInvalidOtp, seterrorMessageInvalidOtp] = useState({
        show: true,
        text: 'El código ingresado no es correcto, ingrese el código enviado por SMS al paciente',
        type: 'error',
        icon: 'error',
    });
    const [sendCode, setSendCode] = useState({
        show: true,
        text: 'Código enviado con éxito',
        type: 'success',
        icon: 'success',
    });
    const [pdfMessage, setPdfMessage] = useState({
        show: true,
        text: 'Aun no esta disponible el documento, intente de nuevo en unos minutos',
        type: 'warning',
        icon: 'warning',
    });
    const [confirmPaperTermsError, setConfirmPaperTermsError] = useState({
        show: true,
        text: 'No se pudo confirmar el consentimiento en papel',
        type: 'warning',
        icon: 'warning',
    });
    return ( 
        <>
        {showErrorOtp ? (
            <SweetAlert2
                {...errorMessageInvalidOtp}
            />
        ) : null}
        {showErrorConfirmPaperTerm ? (
            <SweetAlert2
                {...confirmPaperTermsError}
            />
        ) : null}
        {showAlertPdf ? (
            <SweetAlert2
                    {...pdfMessage}
                />
            ) : null}
        {showAlert ? (
            <SweetAlert2 
                {...sendCode}
            />
        ) : null}
        {showErrorAlert ? (
            <SweetAlert2
                {
                    ...errorMessage
                }
            />
        ) : null}
        {spinnerSubmit ? (
            <>
            <Col xs={12} md={12} lg={12} className="content-spinner">
                <Spinner 
                    animation="border"
                />
                <p>Cargando porfavor espera...</p> 
            </Col>
            </>
        ) : (
            <>
                {consentStatus !== '' ? (
                <>
                    {consentType === 'digital' ? (
                        <>
                            {consentStatus === 'pending' ? (
                                <>
                                    {!sendCodeStatus ? (
                                        <SendConsentSignatureCode 
                                            phoneCode={phoneCode}
                                            phone={phone}
                                            email={email}
                                            errors={errors}
                                            handlerChangeNumber={handlerChangeNumber}
                                            handlerChange={handlerChange}
                                            sendConsentSignatureCodeSMS={sendConsentSignatureCodeSMS}
                                            getPreviewPdf={getPreviewPdf}
                                        />
                                    ) : (
                                        <ValidateConsentSignatureCode 
                                            phoneCode={phoneCode}
                                            phone={phone}
                                            email={email}
                                            errors={errors}
                                            showAlert={showAlert}
                                            resendCodeProcess={resendCodeProcess}
                                            consentCode={consentCode}
                                            handlerChangeNumber={handlerChangeNumber}
                                            resendConsentSignatureCodeSMS={resendConsentSignatureCodeSMS}
                                            validateConsentSignatureCode={validateConsentSignatureCode}
                                            changePhone={changePhone}
                                        />
                                    )}
                                </>
                            ) : (
                                <ConsentSignatureOk
                                    showAlertPdf={showAlertPdf}
                                    getSignedPdf={getSignedPdf}
                                />
                            )}
                        </>
                    ) : consentType === 'paper' ? (
                        <>
                            {consentStatus === 'pending' ? (
                                <ConfirmConsentSignaturePaper 
                                    handlerCancelCansentPaper={handlerCancelCansentPaper}
                                    confirmPaperTerm={confirmPaperTerm}
                                />
                            ) : (
                                <ConsentSignaturePaperOk 
                                    adminInfo={adminInfo}
                                />
                            )}
                        </>
                    ) : null}
                </>
                ) : (
                    <ConsentSignatureType 
                        handlerSelectConsentType={handlerSelectConsentType}
                    />
                )}
            </>
        )}
        </>
    );
}
 
export default ConsentSignature;