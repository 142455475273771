/* eslint-disable default-case */
import {
    INI_GET_STATUS_CONSENT_SIGNATURE,
    GET_STATUS_CONSENT_SIGNATURE,
    ERROR_GET_STATUS_CONSENT_SIGNATURE,
    INI_GET_PDF_SIGNED_CONSENT_SIGNATURE,
    GET_PDF_SIGNED_CONSENT_SIGNATURE,
    ERROR_GET_PDF_SIGNED_CONSENT_SIGNATURE,
    INIT_GET_PDF_PREVIEW_CONSENT_SIGNATURE,
    GET_PDF_PREVIEW_CONSENT_SIGNATURE,
    ERROR_GET_PDF_PREVIEW_CONSENT_SIGNATURE,
    INIT_ACCEPT_TERMS,
    ACCEPT_TERMS,
    ERROR_ACCEPT_TERMS,
    INIT_CONFIRM_TERMS,
    CONFIRM_TERMS,
    ERROR_CONFIRM_TERMS,
    INIT_CONFIRM_PAPER_TERMS,
    CONFIRM_PAPER_TERMS,
    ERROR_CONFIRM_PAPER_TERMS,
    CLEAN_CONSET_SIGNATURE,
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    consentSignature: null,
    pdfPreview: null,
    pdfSigned: null,
    messageError: null,
    errorServer: null,
    sendCodeStatus: null,
    confirmCodeStatus: null,
    confirmPaperCodeStatus: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INI_GET_STATUS_CONSENT_SIGNATURE:  
        case INIT_GET_PDF_PREVIEW_CONSENT_SIGNATURE:  
        case INI_GET_PDF_SIGNED_CONSENT_SIGNATURE:
            return {
                ...state,
                loading: true,
            }  
        case INIT_CONFIRM_TERMS:
            return {
                ...state,
                loading: true,
                confirmCodeStatus: null,
            }   
        case INIT_CONFIRM_PAPER_TERMS:
            return {
                ...state,
                loading: true,
                confirmPaperCodeStatus: null,
            }     
        case INIT_ACCEPT_TERMS:  
            return {
                ...state,
                loading: true,
                sendCodeStatus: null,
                messageError: null,
                errorServer: null,
            }    
        case GET_STATUS_CONSENT_SIGNATURE:
            return {
                ...state,
                loading: false,
                consentSignature: action.payload.consentSignature
            }
        case ERROR_GET_STATUS_CONSENT_SIGNATURE:
            return {
                ...state,
                loading: false,
                consentSignature: null,
            }  
        case ACCEPT_TERMS: 
            return {
                ...state,
                loading: false,
                sendCodeStatus: true,
                messageError: null,
                errorServer: false,
            }
        case ERROR_ACCEPT_TERMS:
            return {
                ...state,
                loading: false,
                sendCodeStatus: false,
                messageError: 'Intente de nuevo en unos minutos',
                errorServer: true,
            }    
        case GET_PDF_PREVIEW_CONSENT_SIGNATURE:
            return {
                ...state,
                pdfPreview: action.payload,
                loading: false,
            }
        case ERROR_GET_PDF_PREVIEW_CONSENT_SIGNATURE:
            return {
                ...state,
                pdfPreview: null,
                loading: false,
            }     
        case CONFIRM_PAPER_TERMS:
            return {
                ...state,
                loading: false,
                confirmPaperCodeStatus: true,
            }   
        case ERROR_CONFIRM_PAPER_TERMS:
            return {
                ...state,
                loading: false,
                confirmPaperCodeStatus: false,
            }     
        case CONFIRM_TERMS:
            return {
                ...state,
                loading: false,
                confirmCodeStatus: true,
            }             
        case ERROR_CONFIRM_TERMS:
            return {
                ...state,
                loading: false,
                confirmCodeStatus: false,
                consentSignature: null,
            }    
        case GET_PDF_SIGNED_CONSENT_SIGNATURE:
            return {
                ...state,
                loading: false,
                pdfSigned: action.payload,
            } 
        case ERROR_GET_PDF_SIGNED_CONSENT_SIGNATURE:
            return {
                ...state,
                loading: false,
                pdfSigned: null,
            }       
        case CLEAN_CONSET_SIGNATURE:
            return {
                ...state,
                loading: false,
                consentSignature: null,
                pdfPreview: null,
                pdfSigned: null,
                messageError: null,
                errorServer: null,
                sendCodeStatus: null,
                confirmCodeStatus: null,
                confirmPaperCodeStatus: null,
            }    
        default:
            return state;    
    }
}