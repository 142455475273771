import {
    INIT_VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED,
    VERIFICATE_DNI_IMED_ERROR,
    INIT_CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED,
    CERTIFICATION_DNI_IMED_ERROR
} from 'happ-redux/happ-types';

const initialState = {
    urlVerificateIdentity: null,
    errorEnrollment: null,
    certificateOk: null,
    certificateDni: null,
    certificateErrorMsg: null,
    loading: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type) {
            case INIT_CERTIFICATION_DNI_IMED:
            case INIT_VERIFICATE_DNI_IMED:
                return {
                    ...state,
                    loading: true,
                }    
            case VERIFICATE_DNI_IMED:
                return {
                    ...state,
                    urlVerificateIdentity: action.payload.data.url_redireccion,
                    loading: false
                }
            case VERIFICATE_DNI_IMED_ERROR:
                return {
                    ...state,
                    urlVerificateIdentity: null,
                    errorEnrollment: action.payload,
                    loading: false
                }
            case CERTIFICATION_DNI_IMED:
                return {
                    ...state,
                    certificateOk: action.payload.msg,
                    certificateDni: action.payload.dni,
                    certificateErrorMsg: null,
                    loading: false
                }
            case CERTIFICATION_DNI_IMED_ERROR:
                return {
                    ...state,
                    certificateOk: null,
                    certificateDni: null,
                    certificateErrorMsg: action.payload,
                    loading: false
                }    
            default:
                return state      
    }
}