import { Row, Col } from 'react-bootstrap';
import { CheckCircleFill } from 'react-bootstrap-icons';

const ConsentSignaturePaperOk = ({adminInfo}) => {
    return (
        <Row>
            <div className='d-flex justify-content-center'>
                <div className='mt-5'>
                    <p className='status-consent-signature'>
                        Estado: 
                        <strong>Consentimiento firmado</strong>
                        <CheckCircleFill 
                            className='icon-check-signature'
                        />
                    </p>
                    <p className='status-consent-signature'>
                        Tipo: 
                        <strong>Físico</strong>
                    </p>
                </div>
            </div>
            <Col sm={6} className='col-consent-confirm-content'>
                <div className='confirm-consent-content justify-content-center text-center'>
                    <p>El consentimiento en formato físico fue registrado por <strong>{adminInfo.name} ({adminInfo.dni})</strong> el día <strong>{adminInfo.date}</strong></p>
                </div>
            </Col>
        </Row>
    );
}
 
export default ConsentSignaturePaperOk;